export const QuoteStatus = {
  Submitted: 'SUBMITTED',
  Quoted: 'QUOTESRETRIEVED',
  PreQuote: 'INPROGRESS',
  UnableToQuote: 'UNABLETOQUOTE'
};

export const QuickQuoteStatus = {
  FullQuoteRetrieved: 'FULLQUOTESRETRIEVED',
  QuickQuoteRetrieved: 'QUICKQUOTERETRIEVED',
  Proceeded: 'PROCEEDED',
  Submitted: 'SUBMITTED'
};

export const QuoteStatusText = {
  [QuoteStatus.Submitted]: 'Submitted',
  [QuoteStatus.Quoted]: 'Quoted',
  [QuoteStatus.PreQuote]: 'Pre-Quote',
  [QuoteStatus.UnableToQuote]: 'Unable to Quote',
  Expired: 'Expired',
  IsIndicativeQuote: 'Indicative quote'
};

export default function getQuoteStatusText(quote) {
  const now = new Date();
  if (isQuickQuoteNotGetFullPrice(quote)) {
    return QuoteStatusText.IsIndicativeQuote;
  }
  if (
    quote.quoteStatus !== QuoteStatus.Submitted &&
    quote.quoteExpiryDate != null &&
    now >= Date.parse(quote.quoteExpiryDate)
  ) {
    return QuoteStatusText.Expired;
  }
  if (quote.quoteStatus == null || quote.quoteStatus === '') {
    return '';
  }
  return QuoteStatusText[quote.quoteStatus];
}

const isQuickQuoteNotGetFullPrice = (quote) => {
  return (
    quote.indicativeRequest &&
    (quote.quickQuoteStatus === QuickQuoteStatus.QuickQuoteRetrieved ||
      quote.quickQuoteStatus === QuickQuoteStatus.Proceeded)
  );
};
