export const DATE_FORMAT_DDMMYYYY = 'DD/MM/YYYY';
export const DATE_FORMAT_MMDDYYYY = 'MM-DD-YYYY';
export const DATE_FORMAT_YYYYMMDD = 'YYYY-MM-DD';
export const DATE_FORMAT_YYYY = 'YYY';
export const DATE_FORMAT_MM = 'MM';
export const DATE_FORMAT_DD = 'DD';
export const DATE_FORMAT_DDMMMMYYYY = 'DD MMMM YYYY';
export const DATE_FORMAT_DDMMMYYYY = 'DD MMM YYYY';
export const DATE_FORMAT_DDMMMMYY = 'DD MMMM YY';
export const DATE_FORMAT_DDMMMYY = 'DD MMM YY';
export const DATE_FORMAT_DOMMMMYYYY = 'Do MMMM YYYY';
export const DATE_FORMAT_DO = 'Do';
export const DATE_TIME_FORMAT_DDMMYYYY = 'DD/MM/YYYY [at] HH:mm';
export const DATE_TIME_FORMAT_DDMMYY = 'DD/MM/YY [at] HH:mm';
export const DATE_TIME_FORMAT_DDMMMMYYYY = 'DD MMMM YYYY, HH:mm';
export const DATE_FORMAT_DDMMYYYYHHMMSS = 'DD/MM/YYYY, HH:mm:ss';
