import leadService from '@/services/lead.service';
import referenceDataService from '@/services/reference-data.service';
import {
  ADD_NOTE,
  CHECK_CAPACITY_VALID,
  CHECK_HAS_ALLOCATION,
  CREATE_A_NEW_QUOTE,
  CREATE_NEW_TIME_SLOT_AND_CAPACITY,
  DELETE_TIME_SLOT_AND_CAPACITY,
  DELETE_CAPACITY_REDUCTION,
  EDIT_LEAD_DETAIL,
  EXPORT_ALL_LEAD,
  EXPORT_CONTACT_DUE,
  FETCH_CALL_OUTCOME_LISTS,
  FETCH_COVER_TYPES,
  FETCH_LEAD_DETAIL,
  FETCH_LEAD_FORM_CONFIG,
  FETCH_LEAD_HISTORY,
  FETCH_LEAD_HISTORY_DETAIL,
  FETCH_LEAD_NOTES,
  FETCH_LIST_LEADS,
  FETCH_LIST_PSL_AGENT,
  FETCH_NEXT_WORKFLOW_STATES,
  FETCH_POSSIBLE_DUPLICATE_LEADS,
  FETCH_PREFERED_CONTACT_TIMES,
  FETCH_QUOTE_LIST_HISTORY,
  FETCH_RECENT_CONTACT_TIME_CHANGE,
  FETCH_TIME_SLOTS,
  FETCH_CAPACITY_REDUCTION,
  FETCH_WORKFLOW_STATES,
  GET_LEAD_DETAIL,
  GET_LIST_D2C_EMAIL_SENT,
  GET_LIST_D2C_SMS_SENT,
  GET_QUOTE_DETAIL,
  HANDLE_ASSIGN_LEAD,
  RESEND_D2C_EMAIL,
  RESEND_D2C_SMS,
  SAVE_TIME_RANGE,
  SAVE_TIME_SLOT_AND_CAPACITY,
  SAVE_TIME_SLOT_AND_CAPACITY_REDUCTION,
  SET_CALL_OUTCOME_LISTS,
  SET_COVER_TYPES,
  SET_LEAD_DETAIL,
  SET_LEAD_FORM_CONFIG,
  SET_LEAD_HISTORY,
  SET_LEAD_HISTORY_DETAIL,
  SET_LEAD_NOTES,
  SET_LIST_LEADS,
  SET_LIST_PSL_AGENT,
  SET_NEXT_WORKFLOW_STATES,
  SET_POSSIBLE_DUPLICATE_LEADS,
  SET_PREFERED_CONTACT_TIMES,
  SET_QUOTE_LIST_HISTORY,
  SET_SEARCHING_STATUS,
  SET_SHOW_HISTORY_DETAIL,
  SET_WORKFLOW_STATES,
  SYNC_QUOTE,
  TOGGLE_SHOW_HISTORY_DETAIL,
  UPDATE_CALL_OUTCOME,
  UPDATE_ONLINE_CALL_OUTCOME,
  UPDATE_WORKFLOW_STATES,
  GET_QUOTE_LINK,
  AUTHENTICATE_IMPERSONATE
} from '@/shared/constants/vuex-key';
import {
  WORKFLOW_STATE,
  WORKFLOW_STATE_TRANSITION
} from '@/shared/constants/workflow-state';

export default {
  async [FETCH_LEAD_DETAIL]({ commit }, payload) {
    const { id, mergeTime } = payload;
    const lead = await leadService.getLeadDetail(id, mergeTime);
    commit(SET_LEAD_DETAIL, lead);
  },
  async [EDIT_LEAD_DETAIL](store, payload) {
    const result = await leadService.editLead(payload);
    return result;
  },

  async [EDIT_LEAD_DETAIL](commit, payload) {
    const result = await leadService.editLead(payload);
    return result;
  },

  async [FETCH_LEAD_FORM_CONFIG](store) {
    const data = await Promise.all([
      referenceDataService.getCoverList(),
      referenceDataService.getPreferredContactTimes(),
      referenceDataService.getAppConfiguration(),
      referenceDataService.getMortgageCategory(),
      referenceDataService.getPreferredContactMethod()
    ]);
    const config = {
      coverList: data[0].value,
      preferredContactTimes: data[1].value,
      insuranceInfoDetailLink: data[2].value.insuranceInfoDetailLink,
      mortgageCategory: data[3].value,
      preferredContactMethod: data[4].value
    };
    store.commit(SET_LEAD_FORM_CONFIG, config);
  },

  async [FETCH_LIST_LEADS](store, payload) {
    const { paging, sorting, keyword, filter, leadType } = payload;
    const data = await leadService.fetchListLeads(
      paging,
      sorting,
      keyword,
      filter,
      leadType
    );
    store.commit(SET_LIST_LEADS, data);
  },

  async [FETCH_POSSIBLE_DUPLICATE_LEADS](store, payload) {
    const { paging, sorting, keyword } = payload;
    const data = await leadService.fetchListPossibleDuplicateLeads(
      paging.current,
      sorting,
      keyword
    );
    store.commit(SET_POSSIBLE_DUPLICATE_LEADS, data);
  },

  async [FETCH_WORKFLOW_STATES](store, payload) {
    const data = await leadService.getWorkflowStates(payload);
    store.commit(SET_WORKFLOW_STATES, data);
  },

  async [FETCH_CALL_OUTCOME_LISTS](store, payload) {
    const data = await leadService.fetchCallOutcomeLists(payload);
    store.commit(SET_CALL_OUTCOME_LISTS, data.value);
  },

  async [FETCH_PREFERED_CONTACT_TIMES](store) {
    const data = await referenceDataService.getPreferredContactTimes();
    store.commit(SET_PREFERED_CONTACT_TIMES, data);
  },

  async [FETCH_LIST_PSL_AGENT](store, payload) {
    const data = await leadService.getListPSLAgent(payload);
    store.commit(SET_LIST_PSL_AGENT, data);
    store.commit(SET_SEARCHING_STATUS, false);
  },

  async [FETCH_LEAD_HISTORY](store, payload) {
    const { leadId, paging } = payload;
    const history = await leadService.getLeadHistory(leadId, paging);
    store.commit(SET_LEAD_HISTORY, history);
  },

  async [FETCH_LEAD_HISTORY_DETAIL](store, payload) {
    const { historyId, summaryTitle } = payload;
    const historyDetail = await leadService.getLeadHistoryDetail(historyId);
    store.commit(SET_LEAD_HISTORY_DETAIL, {
      ...historyDetail.value,
      summaryTitle
    });
  },

  [TOGGLE_SHOW_HISTORY_DETAIL](store, payload) {
    store.commit(SET_SHOW_HISTORY_DETAIL, payload);
  },

  async [FETCH_COVER_TYPES](store) {
    const coverList = await referenceDataService.getCoverList();
    store.commit(SET_COVER_TYPES, coverList.value);
  },

  async [HANDLE_ASSIGN_LEAD](store, payload) {
    const { assigntoUserId } = payload;
    const leadId = store.getters[GET_LEAD_DETAIL].id;
    const leadDetail = await leadService.getLeadDetail(leadId);
    if (
      leadDetail.assigntoUserId ||
      (leadDetail.assigntoUserId == null &&
        leadDetail.status != WORKFLOW_STATE.UNASSIGNED)
    ) {
      return leadService.assignToUser(leadId, assigntoUserId);
    } else {
      const nextStateModel = {
        modelId: leadId,
        trigger: WORKFLOW_STATE_TRANSITION.ASSIGN,
        nextStateParameter: {
          assigntoUserId
        }
      };
      return leadService.triggerNextState(nextStateModel);
    }
  },

  async [UPDATE_CALL_OUTCOME](store, payload) {
    const result = await leadService.triggerNextState(payload);
    return result;
  },

  async [UPDATE_ONLINE_CALL_OUTCOME](store, payload) {
    const result = await leadService.addOnlineCallOutcome(payload);
    return result;
  },

  async [ADD_NOTE](store, payload) {
    const result = await leadService.addNote(payload);
    return result;
  },

  async [FETCH_NEXT_WORKFLOW_STATES](store, leadId) {
    const result = await leadService.getNextLeadManualStates(leadId);
    store.commit(SET_NEXT_WORKFLOW_STATES, result);
  },

  async [UPDATE_WORKFLOW_STATES](store, payload) {
    const result = await leadService.updateLeadStatus(payload);
    return result;
  },

  async [CREATE_A_NEW_QUOTE](store, payload) {
    const result = await leadService.createANewQuote(payload);
    return result;
  },

  async [FETCH_QUOTE_LIST_HISTORY](store, payload) {
    const { leadId, paging } = payload;
    const result = await leadService.getListQuoteHistory(leadId, paging);
    store.commit(SET_QUOTE_LIST_HISTORY, result);
  },

  async [SYNC_QUOTE](store, payload) {
    const result = await leadService.syncQuote(payload);
    return result;
  },

  async [GET_QUOTE_LINK]({ dispatch }, quoteRequestId) {
    const quoteSelectedResult = await dispatch(
      GET_QUOTE_DETAIL,
      quoteRequestId
    );
    const requestObj = {
      brokerId: quoteSelectedResult.value.brokerId,
      productId: quoteSelectedResult.value.productId
    };
    const tokenObj = await dispatch(AUTHENTICATE_IMPERSONATE, requestObj);
    const url = leadService.replaceToken(tokenObj, quoteSelectedResult.value);
    return url;
  },

  async [GET_QUOTE_DETAIL](payload, quoteRequestId) {
    const result = await leadService.getQuoteDetail(quoteRequestId);
    return result;
  },

  async [EXPORT_CONTACT_DUE](store, payload) {
    const result = await leadService.exportContactDue(payload);
    return result;
  },

  async [EXPORT_ALL_LEAD](store, payload) {
    const result = await leadService.exportAllLead(payload);
    return result;
  },

  async [FETCH_TIME_SLOTS](store, payload) {
    const data = await leadService.getListTimeSlot(payload);
    return data;
  },
  async [FETCH_CAPACITY_REDUCTION](store, payload) {
    const data = await leadService.getListCapacityReduction(payload);
    return data;
  },

  async [CHECK_CAPACITY_VALID](store, payload) {
    const data = await leadService.checkIsCapacityValid(payload);
    return data;
  },

  async [SAVE_TIME_SLOT_AND_CAPACITY](store, payload) {
    const data = await leadService.saveTimeSlotAndCapacity(payload);
    return data;
  },

  async [SAVE_TIME_SLOT_AND_CAPACITY_REDUCTION](store, payload) {
    const data = await leadService.saveTimeSlotAndReductionCapacity(payload);
    return data;
  },

  async [CREATE_NEW_TIME_SLOT_AND_CAPACITY](store, payload) {
    const data = await leadService.createTimeSlotAndCapacity(payload);
    return data;
  },

  async [CHECK_HAS_ALLOCATION](store, payload) {
    const data = await leadService.checkHasAllocation(payload);
    return data;
  },

  async [SAVE_TIME_RANGE](store, payload) {
    const data = await leadService.saveTimeRange(payload);
    return data;
  },

  async [DELETE_TIME_SLOT_AND_CAPACITY](store, payload) {
    const data = await leadService.deleteTimeSlotAndCapacity(payload);
    return data;
  },
  async [DELETE_CAPACITY_REDUCTION](store, payload) {
    const data = await leadService.deleteCapacityReduction(payload);
    return data;
  },

  async [FETCH_LEAD_NOTES](store, payload) {
    const result = await leadService.fetchLeadNotes(payload);
    store.commit(SET_LEAD_NOTES, result);
    return result;
  },

  async [FETCH_RECENT_CONTACT_TIME_CHANGE](store, id) {
    const result = await leadService.getRecentContactTimeChangesLogs(id);
    return result;
  },
  async [GET_LIST_D2C_EMAIL_SENT](store, leadId) {
    const result = await leadService.getListD2CEmailSent(leadId);
    return result;
  },
  async [RESEND_D2C_EMAIL](store, payload) {
    const result = await leadService.resendD2CEmail(payload);
    return result;
  },
  async [GET_LIST_D2C_SMS_SENT](store, leadId) {
    const result = await leadService.getListD2CSMSSent(leadId);
    return result;
  },
  async [RESEND_D2C_SMS](store, payload) {
    const result = await leadService.resendD2CSMS(payload);
    return result;
  }
};
