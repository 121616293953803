<template>
  <div class="banner__container">
    <div class="banner__wrapper mx-auto d-flex align-center">
      <p class="mb-0 font-weight-bold">
        Our site uses cookies, by closing this cookie window or continuing to
        use our website you are consenting to our use of cookies. You can
        <a
          class="text-decoration-underline"
          @keydown.enter="$emit('openCookieDialog')"
          @click="$emit('openCookieDialog')"
          >read our policy here</a
        >
        which includes information on how to manage cookies.
      </p>
      <div class="d-flex flex-column font-weight-bold">
        <v-icon size="40" color="#FFF" @click="acceptCookies">mdi-close</v-icon>
        <button type="button" @click="acceptCookies">CLOSE</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CookieBanner',
  methods: {
    acceptCookies() {
      localStorage.setItem('acceptedCookie', JSON.stringify(true));
      window.dispatchEvent(
        new CustomEvent('acceptedCookie-localstorage-changed', {
          detail: {
            storage: JSON.parse(localStorage.getItem('acceptedCookie'))
          }
        })
      );
    }
  }
};
</script>
<style lang="scss" scoped src="./CookieBanner.scss"></style>
