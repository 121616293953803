import { API_URL } from '@/shared/constants/api-url';
import { STORAGE_EXPIRED } from '@/shared/constants/common.constants';
import { STORAGE_KEY } from '@/shared/constants/storage';
import { RESET_STORE } from '@/shared/constants/vuex-key';
import axiosClient from '@/shared/http/axios-client';
import store from '@/store';

class AuthService {
  login(userInfo) {
    return axiosClient.post(API_URL.LOGIN, userInfo);
  }

  loginQuote(userInfo) {
    return axiosClient.post(API_URL.LOGIN_QUOTE, userInfo);
  }

  logout(userId) {
    return axiosClient.post(API_URL.LOGOUT + userId);
  }

  authImpersonate(userInfo) {
    return axiosClient.post(API_URL.AUTH_IMPERSONATE, userInfo);
  }

  refreshTokenQuote() {
    return axiosClient.post(API_URL.REFRESH_QUOTE_TOKEN);
  }

  getToken() {
    const access_token = window.$cookies.get(STORAGE_KEY.ACCESS_TOKEN);
    return access_token;
  }

  getTokenQuote() {
    const access_token = window.$cookies.get(STORAGE_KEY.ACCESS_TOKEN_QUOTE);
    return access_token;
  }

  getUserInfo() {
    const userInfo = window.$cookies.get(STORAGE_KEY.USER_INFO);
    return userInfo;
  }

  setUserData(userInfo, isRememberedMe) {
    window.$cookies.set(STORAGE_KEY.ACCESS_TOKEN, userInfo.access_token, {
      expire: 0
    });
    window.$cookies.set(STORAGE_KEY.USER_ID, userInfo[STORAGE_KEY.USER_ID], {
      expire: 0
    });
    window.$cookies.set(STORAGE_KEY.FIRST_NAME, userInfo.first_name, {
      expire: 0
    });
    window.$cookies.set(STORAGE_KEY.ROLES, userInfo.roles, {
      expire: 0
    });
    window.$cookies.set(STORAGE_KEY.ROLES, JSON.stringify(userInfo.roles), {
      expire: 0
    });
    window.$cookies.set(
      STORAGE_KEY.ACCESS_TOKEN_QUOTE,
      userInfo.quoteSessionToken,
      {
        expire: 0
      }
    );
    if (isRememberedMe) {
      window.$cookies.set(
        STORAGE_KEY.ACCESS_TOKEN,
        userInfo.access_token,
        STORAGE_EXPIRED
      );
      window.$cookies.set(
        STORAGE_KEY.USER_ID,
        userInfo[STORAGE_KEY.USER_ID],
        STORAGE_EXPIRED
      );
      window.$cookies.set(
        STORAGE_KEY.FIRST_NAME,
        userInfo.first_name,
        STORAGE_EXPIRED
      );
      window.$cookies.set(STORAGE_KEY.ROLES, userInfo.roles, STORAGE_EXPIRED);
      window.$cookies.set(
        STORAGE_KEY.ACCESS_TOKEN_QUOTE,
        userInfo.quoteSessionToken,
        STORAGE_EXPIRED
      );
    }
  }

  clearUserInfo() {
    window.$cookies.remove(STORAGE_KEY.ACCESS_TOKEN);
    window.$cookies.remove(STORAGE_KEY.USER_INFO);
    window.$cookies.remove(STORAGE_KEY.USER_ID);
    window.$cookies.remove(STORAGE_KEY.FIRST_NAME);
    window.$cookies.remove(STORAGE_KEY.ROLES);
    window.$cookies.remove(STORAGE_KEY.ACCESS_TOKEN_QUOTE);
    store.dispatch(RESET_STORE);
  }

  forgotPassword(body) {
    return axiosClient.post(API_URL.FORGOT_PASSWORD, body);
  }

  validateChangeKey(changeKey) {
    return axiosClient.post(
      `${API_URL.VALIDATE_CHANGE_KEY}?changeKey=${changeKey}`,
      {}
    );
  }

  resetPassword(body) {
    return axiosClient.post(API_URL.RESET_PASSWORD, body);
  }
}

export default new AuthService();
