import {
  SET_INTRODUCER_DETAIL,
  SET_LIST_INTRODUCER
} from '@/shared/constants/vuex-key';

export default {
  [SET_LIST_INTRODUCER](state, introducerList) {
    state.introducerList = introducerList;
  },
  [SET_INTRODUCER_DETAIL](state, introducerDetail) {
    state.introducerDetail = introducerDetail;
  }
};
