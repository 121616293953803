export const HISTORY_EVENT = {
  CREATE_LEAD: 'Create a Lead',
  UPDATE_LEAD: 'Update a Lead',
  UPDATE_LEAD_CONTACT_DATETIME: 'Update a ContactDateTime',
  UPDATE_CALL_REQUEST_FOR_LEAD: 'Update CallRequest for Lead',
  CREATE_CALL_REQUEST_FOR_LEAD: 'Created CallRequest for lead',
  EMAIL_SENT: 'Send Email',
  SMS_SENT: 'Send SMS',
  CALL_REQUEST_OUTCOME_UPDATED: 'Call Request Outcome updated',
  QUOTE_CREATED: 'Quote created',
  EDIT_TIME_SLOT: 'Edit time slot',
  NOTE_ADDED: 'Add note'
};
