import authService from '@/services/auth.service';
import referenceDataService from '@/services/reference-data.service';
import appConfigurationCodes from '@/shared/constants/app-configuration-codes';
import { ROUTE_NAME } from '@/shared/constants/routing';
import {
  GET_QUOTE_ACCESS_TOKEN,
  GET_USER_ID,
  LOG_OUT
} from '@/shared/constants/vuex-key';
import { mapGetters } from 'vuex';
import FooterComponent from './shared/components/FooterComponent/FooterComponent.vue';
import NavBarComponent from './shared/components/NavBarComponent/NavBarComponent.vue';

export default {
  name: 'App',
  components: {
    NavBarComponent,
    FooterComponent
  },
  computed: {
    ...mapGetters({
      userId: GET_USER_ID,
      quoteAccessToken: GET_QUOTE_ACCESS_TOKEN
    })
  },
  methods: {
    logout() {
      this.$store.dispatch(LOG_OUT, {
        userId: this.userId,
        isSessionTimeOut: true
      });
      authService.clearUserInfo();
      if (
        ![ROUTE_NAME.CREATE_LEAD, ROUTE_NAME.INTRODUCER].includes(
          this.$route.name
        )
      ) {
        this.$router.push({ name: ROUTE_NAME.LOGIN });
      }
    }
  },
  async onActive() {
    const { value, success } =
      await referenceDataService.getAppConfigurationByCode(
        appConfigurationCodes.QUOTE_SESSION_REFRESH_INTERVAL
      );
    let intervalTime = 10;
    if (success && value && value.value) {
      const intervalFromDB = JSON.parse(value.value);
      if (!isNaN(intervalFromDB)) {
        intervalTime = intervalFromDB;
      }
    }
    this.quoteAccessTokenInterval = setInterval(() => {
      if (this.quoteAccessToken) {
        authService.refreshTokenQuote().catch((e) => {
          console.error(e);
          this.logout();
        });
      }
    }, intervalTime * 60 * 1000);
  },
  onIdle() {
    if (this.$route?.meta?.isAuthenRequired === true && this.userId) {
      this.logout();
    }
  }
};
