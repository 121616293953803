import {
  TELEPHONY_LEAD_STATUS_TEXT,
  TELEPHONY_LEAD_STATUS_COLOR,
  ONLINE_LEAD_STATUS_TEXT,
  ONLINE_LEAD_STATUS_COLOR
} from '@/shared/constants/lead-status';

export class Helper {
  static generateClassByStatus(status) {
    switch (status) {
      case TELEPHONY_LEAD_STATUS_TEXT.UNASSIGNED:
        return TELEPHONY_LEAD_STATUS_COLOR.UNASSIGNED;
      case TELEPHONY_LEAD_STATUS_TEXT.ASSIGNED:
        return TELEPHONY_LEAD_STATUS_COLOR.ASSIGNED;
      case TELEPHONY_LEAD_STATUS_TEXT.POLICY_SOLD:
        return TELEPHONY_LEAD_STATUS_COLOR.POLICY_SOLD;
      case TELEPHONY_LEAD_STATUS_TEXT.NO_ANSWER:
        return TELEPHONY_LEAD_STATUS_COLOR.NO_ANSWER;
      case TELEPHONY_LEAD_STATUS_TEXT.WRONG_NUMBER:
        return TELEPHONY_LEAD_STATUS_COLOR.WRONG_NUMBER;
      case TELEPHONY_LEAD_STATUS_TEXT.UNREACHABLE:
        return TELEPHONY_LEAD_STATUS_COLOR.UNREACHABLE;
      case TELEPHONY_LEAD_STATUS_TEXT.NO_SALE:
        return TELEPHONY_LEAD_STATUS_COLOR.NO_SALE;
      case TELEPHONY_LEAD_STATUS_TEXT.DUPLICATE:
        return TELEPHONY_LEAD_STATUS_COLOR.DUPLICATE;
      case TELEPHONY_LEAD_STATUS_TEXT.POSSIBLE_DUPLICATE:
        return TELEPHONY_LEAD_STATUS_COLOR.POSSIBLE_DUPLICATE;
      case TELEPHONY_LEAD_STATUS_TEXT.CALLBACK_ARRANGED:
        return TELEPHONY_LEAD_STATUS_COLOR.CALLBACK_ARRANGED;
      case ONLINE_LEAD_STATUS_TEXT.LEAD_RECEIVED:
        return ONLINE_LEAD_STATUS_COLOR.LEAD_RECEIVED;
      case ONLINE_LEAD_STATUS_TEXT.LINK_SENT:
        return ONLINE_LEAD_STATUS_COLOR.LINK_SENT;
      case ONLINE_LEAD_STATUS_TEXT.LINK_BOUNCED:
        return ONLINE_LEAD_STATUS_COLOR.LINK_BOUNCED;
      case ONLINE_LEAD_STATUS_TEXT.LINK_EXPIRED:
        return ONLINE_LEAD_STATUS_COLOR.LINK_EXPIRED;
      case ONLINE_LEAD_STATUS_TEXT.QUOTE_STARTED:
        return ONLINE_LEAD_STATUS_COLOR.QUOTE_STARTED;
      case ONLINE_LEAD_STATUS_TEXT.QUOTED:
        return ONLINE_LEAD_STATUS_COLOR.QUOTED;
      case ONLINE_LEAD_STATUS_TEXT.POLICY_SOLD:
        return ONLINE_LEAD_STATUS_COLOR.POLICY_SOLD;
      case ONLINE_LEAD_STATUS_TEXT.QUOTE_EXPIRED:
        return ONLINE_LEAD_STATUS_COLOR.QUOTE_EXPIRED;
      case ONLINE_LEAD_STATUS_TEXT.UNABLE_TO_QUOTE:
        return ONLINE_LEAD_STATUS_COLOR.UNABLE_TO_QUOTE;
      case ONLINE_LEAD_STATUS_TEXT.UNQUOTABLE:
        return ONLINE_LEAD_STATUS_COLOR.UNQUOTABLE;
      case ONLINE_LEAD_STATUS_TEXT.DUPLICATE:
        return ONLINE_LEAD_STATUS_COLOR.DUPLICATE;
      case ONLINE_LEAD_STATUS_TEXT.POSSIBLE_DUPLICATE:
        return ONLINE_LEAD_STATUS_COLOR.POSSIBLE_DUPLICATE;
      default:
        return 'badge--no-status';
    }
  }
  static mapResponseToTableData(response, paging) {
    const { pageSize, current } = paging;
    return {
      total: response.data.total,
      paging: {
        current,
        pageCount: Math.ceil(response.data.total / pageSize),
        pageSize
      },
      data: response.data.value
    };
  }

  static openLinkToNewTab(url) {
    window.location =
      'javascript:window.open("' + url + '", null, "");void(0);';
  }

  static cloneDeepObject(anObject) {
    return JSON.parse(JSON.stringify(anObject));
  }
}
