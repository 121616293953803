export const TELEPHONY_LEAD_STATUS_TEXT = {
  UNASSIGNED: 'Unassigned',
  ASSIGNED: 'Assigned',
  POLICY_SOLD: 'Policy Sold',
  NO_ANSWER: 'No Answer',
  WRONG_NUMBER: 'Wrong Number',
  UNREACHABLE: 'Unreachable',
  NO_SALE: 'No Sale',
  DUPLICATE: 'Duplicate',
  POSSIBLE_DUPLICATE: 'Possible Duplicate',
  CALLBACK_ARRANGED: 'Callback Arranged'
};
export const TELEPHONY_LEAD_STATUS_CODE = {
  UNASSIGNED: 'Unassigned',
  ASSIGNED: 'Assigned',
  POSSIBLE_DUPLICATE: 'PossibleDuplicate',
  CALLBACK_ARRANGED: 'CallBackArranged',
  NO_ANSWER: 'NoAnswer',
  POLICY_SOLD: 'PolicySold',
  WRONG_NUMBER: 'WrongNumber',
  UNREACHABLE: 'Unreachable',
  NO_SALE: 'NoSale',
  DUPLICATE: 'Duplicate'
};
export const TELEPHONY_LEAD_STATUS_COLOR = {
  UNASSIGNED: 'grey lighten-6',
  ASSIGNED: 'blue lighten-1',
  POLICY_SOLD: 'green lighten-2',
  NO_ANSWER: 'yellow lighten-1',
  WRONG_NUMBER: 'orange lighten-3',
  UNREACHABLE: 'red lighten-4',
  NO_SALE: 'red lighten-2',
  DUPLICATE: 'grey darken-4',
  POSSIBLE_DUPLICATE: 'grey lighten-5',
  CALLBACK_ARRANGED: 'pink base'
};
export const OPEN_STATUS_TEXTS = [
  TELEPHONY_LEAD_STATUS_TEXT.UNASSIGNED,
  TELEPHONY_LEAD_STATUS_TEXT.ASSIGNED,
  TELEPHONY_LEAD_STATUS_TEXT.NO_ANSWER,
  TELEPHONY_LEAD_STATUS_TEXT.CALLBACK_ARRANGED,
  TELEPHONY_LEAD_STATUS_TEXT.POSSIBLE_DUPLICATE
];
export const OPEN_STATUS_CODES = [
  TELEPHONY_LEAD_STATUS_CODE.UNASSIGNED,
  TELEPHONY_LEAD_STATUS_CODE.ASSIGNED,
  TELEPHONY_LEAD_STATUS_CODE.NO_ANSWER,
  TELEPHONY_LEAD_STATUS_CODE.CALLBACK_ARRANGED,
  TELEPHONY_LEAD_STATUS_CODE.POSSIBLE_DUPLICATE
];
export const CLOSED_STATUS_CODES = [
  TELEPHONY_LEAD_STATUS_CODE.POLICY_SOLD,
  TELEPHONY_LEAD_STATUS_CODE.NO_SALE,
  TELEPHONY_LEAD_STATUS_CODE.WRONG_NUMBER,
  TELEPHONY_LEAD_STATUS_CODE.UNREACHABLE,
  TELEPHONY_LEAD_STATUS_CODE.DUPLICATE
];

export const ONLINE_LEAD_STATUS_TEXT = {
  LEAD_RECEIVED: 'Lead Received',
  LINK_SENT: 'Link Sent',
  LINK_BOUNCED: 'Email Bounced',
  LINK_EXPIRED: 'Lead Expired',
  QUOTE_STARTED: 'Quote Started',
  QUOTED: 'Quoted',
  POLICY_SOLD: 'Policy Sold',
  QUOTE_EXPIRED: 'Quote Expired',
  UNABLE_TO_QUOTE: 'Unable To Quote',
  UNQUOTABLE: 'Unquotable',
  DUPLICATE: 'Duplicate',
  POSSIBLE_DUPLICATE: 'Possible Duplicate'
};
export const ONLINE_LEAD_STATUS_CODE = {
  LEAD_RECEIVED: 'LeadReceived',
  LINK_SENT: 'LinkSent',
  LINK_BOUNCED: 'LinkBounced',
  LINK_EXPIRED: 'LinkExpired',
  QUOTE_STARTED: 'QuoteStarted',
  QUOTED: 'Quoted',
  POLICY_SOLD: 'PolicySold',
  QUOTE_EXPIRED: 'QuoteExpired',
  UNABLE_TO_QUOTE: 'UnableToQuote',
  UNQUOTABLE: 'Unquotable',
  DUPLICATE: 'Duplicate',
  POSSIBLE_DUPLICATE: 'PossibleDuplicate'
};
export const ONLINE_LEAD_STATUS_COLOR = {
  LEAD_RECEIVED: 'grey lighten-6',
  LINK_SENT: 'blue lighten-1',
  LINK_BOUNCED: 'amber darken-2',
  LINK_EXPIRED: 'amber darken-3',
  QUOTE_STARTED: 'yellow lighten-1',
  QUOTED: 'purple lighten-3',
  POLICY_SOLD: 'green lighten-2',
  QUOTE_EXPIRED: 'red lighten-6',
  UNABLE_TO_QUOTE: 'red lighten-7',
  UNQUOTABLE: 'red darken-1',
  DUPLICATE: 'grey darken-4',
  POSSIBLE_DUPLICATE: 'grey lighten-5'
};
