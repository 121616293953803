import { API_URL } from '@/shared/constants/api-url';
import { STORAGE_KEY } from '@/shared/constants/storage';
import axiosClient from '@/shared/http/axios-client';

class IntroducerService {
  convertApiModelToClientModel(model) {
    return {
      ...model,
      actionBtn: model.code
    };
  }

  async checkIntroducerCode(code) {
    if (code === '') return false;
    const response = await axiosClient.get(
      API_URL.CHECK_INTRODUCER_CODE + code
    );
    const isValid = response && !!response.data.value;
    if (isValid) {
      sessionStorage.setItem(STORAGE_KEY.INTRODUCER_CODE, code);
    }
    return isValid;
  }

  async getIntroducerDetailByCode(introducerCode) {
    const url = `${API_URL.GET_INTRODUCER_DETAIL}/${introducerCode}`;
    const response = await axiosClient.get(url);
    return response.data;
  }
}

export default new IntroducerService();
