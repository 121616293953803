import {
  GET_CALL_OUTCOME_LISTS,
  GET_COVER_TYPES,
  GET_LEAD_DETAIL,
  GET_LEAD_FORM_CONFIG,
  GET_LEAD_HISTORY,
  GET_LEAD_HISTORY_DETAIL,
  GET_LEAD_NOTES,
  GET_LIST_LEADS,
  GET_LIST_PSL_AGENT,
  GET_LIST_PSL_AGENT_SELECTED,
  GET_NEXT_WORKFLOW_STATES,
  GET_NO_INTRODUCER_CODE,
  GET_POSSIBLE_DUPLICATE_LEADS,
  GET_QUOTE_LIST_HISTORY,
  GET_SEARCHING_STATUS,
  GET_SHOW_HISTORY_DETAIL,
  GET_WORKFLOW_STATES,
  GET_WORKFLOW_STATES_SELECTED
} from '@/shared/constants/vuex-key';

export default {
  [GET_LEAD_DETAIL](state) {
    return state.lead;
  },
  [GET_LEAD_FORM_CONFIG](state) {
    return state.leadFormConfig;
  },
  [GET_LIST_LEADS](state) {
    return state.listLeads;
  },
  [GET_POSSIBLE_DUPLICATE_LEADS](state) {
    return state.listPossibleDuplicateLeads;
  },
  [GET_SEARCHING_STATUS](state) {
    return state.isSearching;
  },
  [GET_WORKFLOW_STATES](state) {
    return state.listWorkflowStates;
  },
  [GET_LIST_PSL_AGENT](state) {
    return state.listPslAgent;
  },
  [GET_WORKFLOW_STATES_SELECTED](state) {
    return state.listWorkflowStatesSelected;
  },
  [GET_LIST_PSL_AGENT_SELECTED](state) {
    return state.listPslAgentSelected;
  },
  [GET_LEAD_HISTORY](state) {
    return state.history;
  },
  [GET_LEAD_HISTORY_DETAIL](state) {
    return state.historyDetail;
  },
  [GET_SHOW_HISTORY_DETAIL](state) {
    return state.showHistoryDetail;
  },
  [GET_COVER_TYPES](state) {
    return state.coverTypes;
  },
  [GET_CALL_OUTCOME_LISTS](state) {
    return state.callOutComeLists;
  },
  [GET_NEXT_WORKFLOW_STATES](state) {
    return state.nextWorkflowStateList;
  },
  [GET_QUOTE_LIST_HISTORY](state) {
    return state.listQuote;
  },
  [GET_NO_INTRODUCER_CODE](state) {
    return state.noIntroducerCode;
  },
  [GET_LEAD_NOTES](state) {
    return state.leadNotes;
  }
};
