class PagingUtils {
  getPageCount(total, pageSize) {
    if (total > 0) {
      if (total % pageSize > 0) {
        return Math.ceil(total / pageSize);
      } else {
        return total / pageSize;
      }
    } else {
      return 1;
    }
  }

  convertPagingToAPIOptions(paging) {
    return {
      params: {
        $top: paging.pageSize,
        $skip: paging.current > 1 ? paging.pageSize * (paging.current - 1) : 0
      }
    };
  }
}

export default new PagingUtils();
