/**
 *
 * @param {{sortBy: string, sortDesc: boolean}} sorting
 * @returns
 */
export function getOrderForQueryParams(sorting) {
  return sorting?.sortBy
    ? `${sorting.sortBy} ${sorting.sortDesc ? 'desc' : 'asc'}`
    : null;
}

/**
 *
 * @param {string} keyword
 */
export function getSearchForQueryParams(keyword) {
  return keyword ? `"${keyword.trim().replace(/"/g, '\\"')}"` : null;
}
