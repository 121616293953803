import authService from '@/services/auth.service';
import { STORAGE_KEY } from '@/shared/constants/storage';
import {
  AUTHENTICATE_IMPERSONATE,
  AUTHENTICATE_REFRESH_QUOTE_TOKEN,
  AUTHENTICATE_USER,
  AUTHENTICATE_USER_QUOTE,
  HANDLE_RESET_PASSWORD_ERROR_MESSAGE,
  HANDLE_RESET_PASSWORD_FAILURE,
  HANDLE_RESET_PASSWORD_SUCCESS,
  LOG_OUT,
  SET_CURRENT_USER_ROLES,
  SET_FIRST_NAME,
  SET_LOGGEDIN_STATUS,
  SET_RESET_PASSWORD_ERROR_MESSAGE,
  SET_RESET_PASSWORD_FAILURE,
  SET_RESET_PASSWORD_SUCCESS,
  SET_SESSION_TIME_OUT_STATUS,
  SET_USER_ID,
  SET_USER_INFO
} from '@/shared/constants/vuex-key';

export default {
  async [AUTHENTICATE_USER]({ commit }, accountInfo) {
    commit(SET_SESSION_TIME_OUT_STATUS, false);
    const response = await authService.login(accountInfo);
    commit(SET_LOGGEDIN_STATUS, response.status);
    commit(SET_USER_INFO, response.data);
    commit(SET_FIRST_NAME, response.data.first_name);
    commit(SET_USER_ID, response.data[STORAGE_KEY.USER_ID]);
    commit(SET_CURRENT_USER_ROLES, response.data[STORAGE_KEY.ROLES]);
    return response;
  },
  async [LOG_OUT]({ commit }, { userId, isSessionTimeOut }) {
    try {
      const response = await authService.logout(userId);
      return response;
    } catch (e) {
      console.error(e);
    } finally {
      commit(SET_LOGGEDIN_STATUS, false);
      commit(SET_USER_INFO, null);
      commit(SET_USER_ID, null);
      commit(SET_SESSION_TIME_OUT_STATUS, isSessionTimeOut ? true : false);
    }
  },
  [HANDLE_RESET_PASSWORD_FAILURE]({ commit }, failure) {
    commit(SET_RESET_PASSWORD_FAILURE, failure);
  },
  [HANDLE_RESET_PASSWORD_SUCCESS]({ commit }, success) {
    commit(SET_RESET_PASSWORD_SUCCESS, success);
  },
  [HANDLE_RESET_PASSWORD_ERROR_MESSAGE]({ commit }, resetPasswordErrorMessage) {
    commit(SET_RESET_PASSWORD_ERROR_MESSAGE, resetPasswordErrorMessage);
  },
  async [AUTHENTICATE_USER_QUOTE](commit, accountInfo) {
    const response = await authService.loginQuote(accountInfo);
    return response;
  },
  async [AUTHENTICATE_IMPERSONATE](commit, accountInfo) {
    const response = await authService.authImpersonate(accountInfo);
    return response;
  },
  async [AUTHENTICATE_REFRESH_QUOTE_TOKEN]() {
    const response = await authService.refreshTokenQuote();
    return response;
  }
};
