import TwitterIcon from '@/assets/icons/ic_twitter.svg';
import { SHOW_BANNER_PAGE_LIST } from '@/shared/constants/common.constants';
import { DIALOG_TYPE } from '@/shared/constants/dialog-type';
import {
  CONTACT_US_LINK,
  FAQS_LINK,
  TWITTER_LINK
} from '@/shared/constants/external-link';
import { ADVISER_HOME_PAGE_URL, HOME_PAGE_URL } from '@/shared/constants/page';
import { ROUTE_NAME } from '@/shared/constants/routing';
import { mdiOpenInNew } from '@mdi/js';
import CookieBanner from './CookieBanner/CookieBanner.vue';
import BaseDialog from './components/BaseDialog';

export default {
  name: 'TheFooter',
  components: {
    BaseDialog,
    TwitterIcon,
    CookieBanner,
    TermsAndConditions: () => import('./dialog-views/TermsAndConditions'),
    FairProcessingNotice: () => import('./dialog-views/FairProcessingNotice'),
    AccessibilityDialog: () => import('./dialog-views/AccessibilityDialog'),
    ModernSlaveryAct: () => import('./dialog-views/ModernSlaveryAct'),
    CookiesDialog: () => import('./dialog-views/CookiesDialog')
  },
  data() {
    return {
      dialogType: '',
      FAQS_LINK,
      CONTACT_US_LINK,
      DIALOG_TYPE,
      HOME_PAGE_URL,
      ADVISER_HOME_PAGE_URL,
      mdiOpenInNew,
      showCookieBanner: false
    };
  },
  methods: {
    showDialog(title, type) {
      this.dialogType = type;
      this.$refs.dialog.open(title);
    },
    linkToTwitter() {
      window.open(TWITTER_LINK, '_blank');
    },
    isShowCookieBanner() {
      let isShowInRoute = SHOW_BANNER_PAGE_LIST.includes(this.$route.name);
      return this.showCookieBanner && isShowInRoute;
    }
  },
  computed: {
    homeLink() {
      return { name: ROUTE_NAME.INDEX };
    },
    currentYear() {
      return new Date().getFullYear();
    }
  },
  mounted() {
    this.$root.$on('openDialog', ({ title, type }) => {
      this.showDialog(title, type);
    });
    this.showCookieBanner = !JSON.parse(localStorage.getItem('acceptedCookie'));
    window.addEventListener('acceptedCookie-localstorage-changed', (event) => {
      this.showCookieBanner = !event.detail.storage;
    });
  }
};
