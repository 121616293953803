import { STORAGE_KEY } from '@/shared/constants/storage';

export const getInitialState = () => {
  const userId = window.$cookies.get(STORAGE_KEY.USER_ID);
  const firstName = window.$cookies.get(STORAGE_KEY.FIRST_NAME);
  const userRoles = window.$cookies.get(STORAGE_KEY.ROLES);
  const quoteSessionToken = window.$cookies.get(STORAGE_KEY.ACCESS_TOKEN_QUOTE);
  return {
    userInfo: {},
    userRoles: userRoles ?? [],
    isLoggedIn: !!userId,
    userId: Number(userId),
    firstName,
    isSessionTimeOut: false,
    resetPasswordFailure: false,
    resetPasswordSuccess: false,
    resetPasswordErrorMessage: '',
    quoteSessionToken: quoteSessionToken
  };
};
