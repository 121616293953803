export const STORAGE_KEY = {
  INTRODUCER_CODE: 'IntroducerCode',
  ACCESS_TOKEN: 'access_token',
  ACCESS_TOKEN_QUOTE: 'access_token_quote',
  IS_REMEMBERED_ME: 'isRememberedMe',
  USER_INFO: 'userInfo',
  FIRST_NAME: 'firstName',
  USER_ID: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name',
  ROLES: 'roles',
  DESTINATION_TARGET_ROUTE: 'destinationTargetRoute',
  LEAD_TYPE: 'leadType'
};
