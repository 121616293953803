import Vue from 'vue';
import '@mdi/font/css/materialdesignicons.min.css';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors';
Vue.use(Vuetify);

export default new Vuetify({
  customVariables: ['~/styles/variables.scss'],
  treeShake: true,
  theme: {
    themes: {
      light: {
        primary: colors.cyan.darken4,
        secondary: {
          base: '#fab519'
        },
        red: {
          base: '#df3044',
          lighten5: '#ffeff1'
        },
        // accent: '#9c27b0',
        error: '#df3044',
        // warning: '#9c27b0',
        // info: '#9c27b0',
        success: '#1d8649',
        anchor: '#106175',
        checkboxSelected: '#7c869a'
      }
    }
  }
});
