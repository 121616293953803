import {
  SET_CALL_OUTCOME_LISTS,
  SET_COVER_TYPES,
  SET_LEAD_DETAIL,
  SET_LEAD_FORM_CONFIG,
  SET_LEAD_HISTORY,
  SET_LEAD_HISTORY_DETAIL,
  SET_LEAD_NOTES,
  SET_LIST_LEADS,
  SET_LIST_PSL_AGENT,
  SET_LIST_PSL_AGENT_SELECTED,
  SET_NEXT_WORKFLOW_STATES,
  SET_NO_INTRODUCER_CODE,
  SET_POSSIBLE_DUPLICATE_LEADS,
  SET_QUOTE_LIST_HISTORY,
  SET_SEARCHING_STATUS,
  SET_SHOW_HISTORY_DETAIL,
  SET_WORKFLOW_STATES,
  SET_WORKFLOW_STATES_SELECTED
} from '@/shared/constants/vuex-key';

export default {
  [SET_LEAD_DETAIL](store, lead) {
    store.lead = lead;
  },
  [SET_LEAD_FORM_CONFIG](store, config) {
    store.leadFormConfig = config;
  },
  [SET_LIST_LEADS](store, listLeads) {
    store.listLeads = listLeads;
  },
  [SET_POSSIBLE_DUPLICATE_LEADS](store, possibleDuplicateLeads) {
    store.listPossibleDuplicateLeads = possibleDuplicateLeads;
  },
  [SET_SEARCHING_STATUS](store, isSearching) {
    store.isSearching = isSearching;
  },
  [SET_WORKFLOW_STATES](store, listWorkflowStates) {
    store.listWorkflowStates = listWorkflowStates;
  },
  [SET_LIST_PSL_AGENT](store, listPslAgent) {
    store.listPslAgent = listPslAgent;
  },
  [SET_WORKFLOW_STATES_SELECTED](store, listWorkflowStatesSelected) {
    store.listWorkflowStatesSelected = listWorkflowStatesSelected;
  },
  [SET_LIST_PSL_AGENT_SELECTED](store, listPslAgentSelected) {
    store.listPslAgentSelected = listPslAgentSelected;
  },
  [SET_LEAD_HISTORY](store, history) {
    '';
    store.history = history;
  },
  [SET_LEAD_HISTORY_DETAIL](store, historyDetail) {
    store.historyDetail = historyDetail;
  },
  [SET_SHOW_HISTORY_DETAIL](store, showHistoryDetail) {
    store.showHistoryDetail = showHistoryDetail;
  },
  [SET_COVER_TYPES](store, coverTypes) {
    store.coverTypes = coverTypes;
  },
  [SET_CALL_OUTCOME_LISTS](store, callOutComeLists) {
    store.callOutComeLists = callOutComeLists;
  },
  [SET_NEXT_WORKFLOW_STATES](store, nextWorkflowStateList) {
    store.nextWorkflowStateList = nextWorkflowStateList;
  },
  [SET_QUOTE_LIST_HISTORY](store, listQuote) {
    store.listQuote = listQuote;
  },
  [SET_NO_INTRODUCER_CODE](store, noIntroducerCode) {
    store.noIntroducerCode = noIntroducerCode;
  },
  [SET_LEAD_NOTES](store, leadNotes) {
    store.leadNotes = leadNotes;
  }
};
