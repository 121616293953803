<template>
  <div class="text-center">
    <v-dialog
      content-class="footer-dialog"
      scrollable
      v-model="dialog"
      :max-width="maxWidth"
      @click:outside="cancel"
      @keydown.esc="cancel"
    >
      <v-card :class="[windowClass]">
        <h2 class="dialog-title mb-3 mb-md-4 text-bold">{{ title }}</h2>
        <v-btn
          icon
          size="50"
          tabindex="1"
          @keydown.enter="cancel"
          @click="cancel"
          class="btn-close"
        >
          <v-icon>{{ mdiClose }}</v-icon>
        </v-btn>
        <v-card-text>
          <slot name="content" :isShow="dialog" :data="data"></slot>
        </v-card-text>

        <v-card-actions class="mt-6" v-if="options.hasActionBtn">
          <v-spacer></v-spacer>

          <BaseButton
            id="CancelBtn"
            className="btn-normal btn-small"
            tabindex="1"
            :text="disagreeBtnText"
            @keydown.enter="cancel"
            @click="cancel"
          />

          <BaseButton
            id="AgreeBtn"
            className="btn-primary btn-small"
            tabindex="1"
            :text="agreeBtnText"
            @keydown.enter="cancel"
            @click="agree"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import BaseButton from './BaseButton.vue';
import { mdiClose } from '@mdi/js';

export default {
  name: 'BaseDialog',
  components: {
    BaseButton
  },
  props: {
    windowClass: String,
    maxWidth: {
      type: Number,
      default: 800
    }
  },
  data() {
    return {
      dialog: false,
      title: '',
      options: {
        agreeBtnText: 'Agree',
        disagreeBtnText: 'Disagree',
        hasActionBtn: false
      },
      resolve: () => {},
      reject: () => {},
      mdiClose,
      data: null
    };
  },
  methods: {
    open(title, data) {
      this.dialog = true;
      this.title = title;
      this.data = data;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree() {
      this.resolve(true);
      this.dialog = false;
    },
    cancel() {
      this.resolve(false);
      this.dialog = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.v-dialog {
  * {
    color: $black;
  }

  .v-card {
    padding: 40px 28px 0px 48px;

    &.confirm-dialog {
      padding: 28px 37px;
      border-radius: 20px;
      margin-right: auto;
      margin-left: auto;
    }

    .v-card__text {
      padding: 0;
    }
  }

  .btn-close {
    position: absolute;
    top: 23px;
    right: 16px;
    font-size: 30px;
    color: $black-2;

    .mdi-close {
      font-size: 32px;
    }
  }
}

@media #{map-get($display-breakpoints, 'md-and-down')} {
  .v-dialog {
    .v-card {
      padding: 32px 16px 0px 24px;

      &.confirm-dialog {
        border-radius: 10px;
        padding: 16px 24px 24px;
      }

      .v-card__text {
        padding: 0;
      }
    }

    .btn-close {
      top: 15px;
      right: 5px;
    }
  }
}
</style>
