import {
  GET_CURRENT_USER_ROLES,
  GET_FIRST_NAME,
  GET_LOGGEDIN_STATUS,
  GET_QUOTE_ACCESS_TOKEN,
  GET_RESET_PASSWORD_ERROR_MESSAGE,
  GET_RESET_PASSWORD_FAILURE,
  GET_RESET_PASSWORD_SUCCESS,
  GET_SESSION_TIME_OUT_STATUS,
  GET_USER_ID,
  GET_USER_INFO
} from '@/shared/constants/vuex-key';

export default {
  [GET_LOGGEDIN_STATUS](state) {
    return state.isLoggedIn;
  },
  [GET_USER_INFO](state) {
    return state.userInfo;
  },
  [GET_FIRST_NAME](state) {
    return state.firstName;
  },
  [GET_USER_ID](state) {
    return state.userId;
  },
  [GET_SESSION_TIME_OUT_STATUS](state) {
    return state.isSessionTimeOut;
  },
  [GET_RESET_PASSWORD_FAILURE](state) {
    return state.resetPasswordFailure;
  },
  [GET_RESET_PASSWORD_SUCCESS](state) {
    return state.resetPasswordSuccess;
  },
  [GET_RESET_PASSWORD_ERROR_MESSAGE](state) {
    return state.resetPasswordErrorMessage;
  },
  [GET_CURRENT_USER_ROLES](state) {
    return state.userRoles;
  },
  [GET_QUOTE_ACCESS_TOKEN](state) {
    return state.quoteSessionToken;
  }
};
