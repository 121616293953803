export const PAGE_TITLE = {
  HOME_PAGE: 'Paymentshield | Home',
  LOGIN_PAGE: 'Paymentshield | Login',
  LEAD_CAPTURE_FORM_PAGE: 'Paymentshield | Lead Capture Form',
  INTRODUCER_CODE_PAGE: 'Paymentshield | Introducer Code',
  EDIT_LEAD: 'Paymentshield | Edit Lead',
  LIST_LEADS: 'Paymentshield | List Leads',
  LIST_INTRODUCER_CODE: 'Paymentshield | List Introducers',
  LEAD_DETAIL: 'Paymentshield | Lead Detail',
  FORGOT_PASSWORD: 'Paymentshield | Forgot Password',
  RESET_PASSWORD: 'Paymentshield | Reset Password',
  ADMIN_SETTING: 'Paymentshield | Admin settings',
  POSSIBLE_DUPLICATES: 'Paymentshield | Possible Duplicates',
  SELECT_REFERRAL_TYPE: 'Paymentshield | Select Referral Type'
};
