export default {
  INSURANCE_INFO_DETAIL_LINK: 'InsuranceInfoDetailLink',
  MAX_RING_OUT_TIMES: 'MaxRingOutTimes',
  COOL_DOWN_TIME: 'CoolDownTime',
  INTRODUCER_LINKS: 'IntroducerLinks',
  REFERRAL_RELEASE_DATE: 'ReferralReleaseDate',
  CONTACT_REMINDER_TIME: 'ContactReminderTime',
  ALLOWED_BRANCHES: 'AllowedBranches',
  PSL_PHONE_NUMBER: 'PSLPhoneNumber',
  ADVISER_HUB_LOGIN_LINK: 'AdviserHubLoginLink',
  QUOTE_DOMAINS: 'QuoteDomains',
  CONTACT_TIME_NOTIFICATION_EXPIRY_TIME: 'ContactTimeNotificationExpiryTime',
  ONLINE_LEAD_EMAIL_CONFIGURATION: 'OnlineLeadEmailConfiguration',
  LEAD_EXPIRE_CONFIGURATION: 'LeadExpireConfiguration',
  LEAD_NEVER_QUOTED_TIME: 'LeadNeverQuotedTime',
  QUOTE_SESSION_REFRESH_INTERVAL: 'QuoteSessionRefreshInterval'
};
