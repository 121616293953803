import introducerService from '@/services/introducer.service';
import {
  FETCH_INTRODUCER_DETAIL,
  SET_INTRODUCER_DETAIL
} from '@/shared/constants/vuex-key';

export default {
  async [FETCH_INTRODUCER_DETAIL](store, introducerCode) {
    const data = await introducerService.getIntroducerDetailByCode(
      introducerCode
    );
    store.commit(SET_INTRODUCER_DETAIL, data.value);
  }
};
