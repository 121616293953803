import { STATUS_CODE } from '@/shared/constants/http-status-code';
import {
  SET_FIRST_NAME,
  SET_LOGGEDIN_STATUS,
  SET_RESET_PASSWORD_ERROR_MESSAGE,
  SET_RESET_PASSWORD_FAILURE,
  SET_RESET_PASSWORD_SUCCESS,
  SET_SESSION_TIME_OUT_STATUS,
  SET_USER_ID,
  SET_USER_INFO,
  SET_CURRENT_USER_ROLES
} from '@/shared/constants/vuex-key';

export default {
  [SET_LOGGEDIN_STATUS](state, status) {
    state.isLoggedIn = status === STATUS_CODE.SUCCESS;
  },
  [SET_USER_INFO](state, userInfo) {
    state.userInfo = userInfo;
  },
  [SET_FIRST_NAME](state, firstName) {
    state.firstName = firstName;
  },
  [SET_USER_ID](state, userId) {
    state.userId = userId;
  },
  [SET_SESSION_TIME_OUT_STATUS](state, isSessionTimeOut) {
    state.isSessionTimeOut = isSessionTimeOut;
  },
  [SET_RESET_PASSWORD_ERROR_MESSAGE](state, resetPasswordErrorMessage) {
    state.resetPasswordErrorMessage = resetPasswordErrorMessage;
  },
  [SET_RESET_PASSWORD_FAILURE](state, resetPasswordFailure) {
    state.resetPasswordFailure = resetPasswordFailure;
  },
  [SET_RESET_PASSWORD_SUCCESS](state, resetPasswordSuccess) {
    state.resetPasswordSuccess = resetPasswordSuccess;
  },
  [SET_CURRENT_USER_ROLES](state, userRoles) {
    state.userRoles = userRoles;
  }
};
