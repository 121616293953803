//action
export const AUTHENTICATE_USER = 'AUTHENTICATE_USER';
export const AUTHENTICATE_USER_QUOTE = 'AUTHENTICATE_USER_QUOTE';
export const AUTHENTICATE_IMPERSONATE = 'AUTHENTICATE_IMPERSONATE';
export const AUTHENTICATE_REFRESH_QUOTE_TOKEN =
  'AUTHENTICATE_REFRESH_QUOTE_TOKEN';
export const LOG_OUT = 'LOG_OUT';
export const FETCH_LIST_INTRODUCER = 'FETCH_LIST_INTRODUCER';
export const FETCH_LEAD_FORM_CONFIG = 'FETCH_LEAD_FORM_CONFIG';
export const FETCH_LEAD_DETAIL = 'FETCH_LEAD_DETAIL';
export const EDIT_LEAD_DETAIL = 'EDIT_LEAD_DETAIL';
export const FETCH_LEAD_HISTORY = 'FETCH_LEAD_HISTORY';
export const FETCH_QUOTE_LIST_HISTORY = 'FETCH_QUOTE_LIST_HISTORY';
export const FETCH_INTRODUCER_DETAIL = 'FETCH_INTRODUCER_DETAIL';
export const HANDLE_RESET_PASSWORD_SUCCESS = 'HANDLE_RESET_PASSWORD_SUCCESS';
export const HANDLE_RESET_PASSWORD_FAILURE = 'HANDLE_RESET_PASSWORD_FAILURE';
export const HANDLE_RESET_PASSWORD_ERROR_MESSAGE =
  'HANDLE_RESET_PASSWORD_ERROR_MESSAGE';
export const FETCH_LIST_LEADS = 'FETCH_LIST_LEADS';
export const FETCH_POSSIBLE_DUPLICATE_LEADS = 'FETCH_POSSIBLE_DUPLICATE_LEADS';
export const FETCH_WORKFLOW_STATES = 'FETCH_WORKFLOW_STATES';
export const FETCH_LIST_PSL_AGENT = 'FETCH_LIST_PSL_AGENT';
export const FETCH_LEAD_HISTORY_DETAIL = 'FETCH_LEAD_HISTORY_DETAIL';
export const TOGGLE_SHOW_HISTORY_DETAIL = 'TOGGLE_SHOW_HISTORY_DETAIL';
export const FETCH_COVER_TYPES = 'FETCH_COVER_TYPES';
export const HANDLE_ASSIGN_LEAD = 'HANDLE_ASSIGN_LEAD';
export const FETCH_PREFERED_CONTACT_TIMES = 'FETCH_PREFERED_CONTACT_TIMES';
export const FETCH_CALL_OUTCOME_LISTS = 'FETCH_CALL_OUTCOME_LISTS';
export const UPDATE_CALL_OUTCOME = 'UPDATE_CALL_OUTCOME';
export const UPDATE_ONLINE_CALL_OUTCOME = 'UPDATE_ONLINE_CALL_OUTCOME';
export const ADD_NOTE = 'ADD_NOTE';
export const FETCH_NEXT_WORKFLOW_STATES = 'FETCH_NEXT_WORKFLOW_STATES';
export const SYNC_QUOTE = 'SYNC_QUOTE';
export const RESET_STORE = 'RESET_STORE';
export const FETCH_TIME_SLOTS = 'FETCH_TIME_SLOTS';
export const FETCH_CAPACITY_REDUCTION = 'FETCH_CAPACITY_REDUCTION';
export const CHECK_CAPACITY_VALID = 'CHECK_CAPACITY_VALID';
export const SAVE_TIME_SLOT_AND_CAPACITY = 'SAVE_TIME_SLOT_AND_CAPACITY';
export const SAVE_TIME_SLOT_AND_CAPACITY_REDUCTION =
  'SAVE_TIME_SLOT_AND_CAPACITY_REDUCTION';
export const CREATE_NEW_TIME_SLOT_AND_CAPACITY =
  'CREATE_NEW_TIME_SLOT_AND_CAPACITY';
export const SAVE_TIME_RANGE = 'SAVE_TIME_RANGE';
export const CHECK_HAS_ALLOCATION = 'CHECK_HAS_ALLOCATION';
export const DELETE_TIME_SLOT_AND_CAPACITY = 'DELETE_TIME_SLOT_AND_CAPACITY';
export const DELETE_CAPACITY_REDUCTION = 'DELETE_CAPACITY_REDUCTION';
export const FETCH_LEAD_NOTES = 'FETCH_LEAD_NOTES';

export const EXPORT_CONTACT_DUE = 'EXPORT_CONTACT_DUE';
export const EXPORT_ALL_LEAD = 'EXPORT_ALL_LEAD';
export const RESEND_D2C_EMAIL = 'RESEND_D2C_EMAIL';
export const GET_LIST_D2C_EMAIL_SENT = 'GET_LIST_D2C_EMAIL_SENT';
export const RESEND_D2C_SMS = 'RESEND_D2C_SMS';
export const GET_LIST_D2C_SMS_SENT = 'GET_LIST_D2C_SMS_SENT';
export const CANCEL_PENDING_REQUESTS = 'CANCEL_PENDING_REQUESTS';
export const GET_QUOTE_LINK = 'GET_QUOTE_LINK';

//mutation
export const SET_LOGGEDIN_STATUS = 'SET_LOGGEDIN_STATUS';
export const SET_USER_INFO = 'SET_USER_INFO';
export const SET_USER_ID = 'SET_USER_ID';
export const SET_SESSION_TIME_OUT_STATUS = 'SET_SESSION_TIME_OUT_STATUS';
export const SET_LIST_INTRODUCER = 'SET_LIST_INTRODUCER';
export const SET_LEAD_DETAIL = 'SET_LEAD_DETAIL';
export const SET_LEAD_HISTORY = 'SET_LEAD_HISTORY';
export const SET_LEAD_FORM_CONFIG = 'SET_LEAD_FORM_CONFIG';
export const SET_FIRST_NAME = 'SET_FIRST_NAME';
export const SET_RESET_PASSWORD_SUCCESS = 'SET_RESET_PASSWORD_SUCCESS';
export const SET_RESET_PASSWORD_FAILURE = 'SET_RESET_PASSWORD_FAILURE';
export const SET_RESET_PASSWORD_ERROR_MESSAGE =
  'SET_RESET_PASSWORD_ERROR_MESSAGE';
export const SET_LIST_LEADS = 'SET_LIST_LEADS';
export const SET_POSSIBLE_DUPLICATE_LEADS = 'SET_POSSIBLE_DUPLICATE_LEADS';
export const SET_SEARCHING_STATUS = 'SET_SEARCHING_STATUS';
export const SET_WORKFLOW_STATES = 'SET_WORKFLOW_STATES';
export const SET_LIST_PSL_AGENT = 'SET_LIST_PSL_AGENT';
export const SET_WORKFLOW_STATES_SELECTED = 'SET_WORKFLOW_STATES_SELECTED';
export const SET_LIST_PSL_AGENT_SELECTED = 'SET_LIST_PSL_AGENT_SELECTED';
export const SET_SHOW_HISTORY_DETAIL = 'SET_SHOW_HISTORY_DETAIL';
export const SET_LEAD_HISTORY_DETAIL = 'SET_LEAD_HISTORY_DETAIL';
export const SET_COVER_TYPES = 'SET_COVER_TYPES';
export const SET_LIST_PSL_AGENTS = 'SET_LIST_PSL_AGENTS';
export const SET_PREFERED_CONTACT_TIMES = 'SET_PREFERED_CONTACT_TIMES';
export const SET_CALL_OUTCOME_LISTS = 'SET_CALL_OUTCOME_LISTS';
export const SET_NEXT_WORKFLOW_STATES = 'SET_NEXT_WORKFLOW_STATES';
export const UPDATE_WORKFLOW_STATES = 'UPDATE_WORKFLOW_STATES';
export const CREATE_A_NEW_QUOTE = 'CREATE_A_NEW_QUOTE';
export const SET_QUOTE_LIST_HISTORY = 'SET_QUOTE_LIST_HISTORY';
export const SET_INTRODUCER_DETAIL = 'SET_INTRODUCER_DETAIL';
export const SET_NO_INTRODUCER_CODE = 'SET_NO_INTRODUCER_CODE';
export const SET_CURRENT_USER_ROLES = 'SET_CURRENT_USER_ROLES';
export const SET_LEAD_NOTES = 'SET_LEAD_NOTES';
export const CLEAR_ABORT_CONTROLLERS = 'CLEAR_ABORT_CONTROLLERS';
export const ADD_ABORT_CONTROLLER = 'ADD_ABORT_CONTROLLER';

//getter
export const GET_LOGGEDIN_STATUS = 'GET_LOGGEDIN_STATUS';
export const GET_USER_INFO = 'GET_USER_INFO';
export const GET_USER_ID = 'GET_USER_ID';
export const GET_SESSION_TIME_OUT_STATUS = 'GET_SESSION_TIME_OUT_STATUS';
export const GET_LIST_INTRODUCER = 'GET_LIST_INTRODUCER';
export const GET_LIST_LEADS = 'GET_LIST_LEADS';
export const GET_POSSIBLE_DUPLICATE_LEADS = 'GET_POSSIBLE_DUPLICATE_LEADS';
export const GET_LEAD_DETAIL = 'GET_LEAD_DETAIL';
export const GET_LEAD_FORM_CONFIG = 'GET_LEAD_FORM_CONFIG';
export const GET_FIRST_NAME = 'GET_FIRST_NAME';
export const GET_RESET_PASSWORD_SUCCESS = 'GET_RESET_PASSWORD_SUCCESS';
export const GET_RESET_PASSWORD_FAILURE = 'GET_RESET_PASSWORD_FAILURE';
export const GET_RESET_PASSWORD_ERROR_MESSAGE =
  'GET_RESET_PASSWORD_ERROR_MESSAGE';
export const GET_SEARCHING_STATUS = 'GET_SEARCHING_STATUS';
export const GET_WORKFLOW_STATES = 'GET_WORKFLOW_STATES';
export const GET_NEXT_WORKFLOW_STATES = 'GET_NEXT_WORKFLOW_STATES';
export const GET_LIST_PSL_AGENT = 'GET_LIST_PSL_AGENT';
export const GET_WORKFLOW_STATES_SELECTED = 'GET_WORKFLOW_STATES_SELECTED';
export const GET_LIST_PSL_AGENT_SELECTED = 'GET_LIST_PSL_AGENT_SELECTED';
export const GET_LEAD_HISTORY = 'GET_LEAD_HISTORY';
export const GET_SHOW_HISTORY_DETAIL = 'GET_SHOW_HISTORY_DETAIL';
export const GET_LEAD_HISTORY_DETAIL = 'GET_LEAD_HISTORY_DETAIL';
export const GET_COVER_TYPES = 'GET_COVER_TYPES';
export const GET_PREFERED_CONTACT_TIMES = 'GET_PREFERED_CONTACT_TIMES';
export const GET_CALL_OUTCOME_LISTS = 'GET_CALL_OUTCOME_LISTS';
export const GET_QUOTE_LIST_HISTORY = 'GET_QUOTE_LIST_HISTORY';
export const GET_QUOTE_DETAIL = 'GET_QUOTE_DETAIL';
export const GET_INTRODUCER_DETAIL = 'GET_INTRODUCER_DETAIL';
export const GET_NO_INTRODUCER_CODE = 'GET_NO_INTRODUCER_CODE';
export const GET_CURRENT_USER_ROLES = 'GET_CURRENT_USER_ROLES';
export const GET_QUOTE_ACCESS_TOKEN = 'GET_QUOTE_ACCESS_TOKEN';
export const GET_LEAD_NOTES = 'GET_LEAD_NOTES';
export const FETCH_RECENT_CONTACT_TIME_CHANGE =
  'FETCH_RECENT_CONTACT_TIME_CHANGE';

// CustomerHub Footer
export const SETTING_GET_LOADING = 'SETTING_GET_LOADING';
export const SETTING_GET_LOADING_ID = 'SETTING_GET_LOADING_ID';
export const SETTING_SET_LOADING_ID = 'SETTING_SET_LOADING_ID';
export const MESSAGE_CLEAR = 'MESSAGE_CLEAR';
