import MyAccountIcon from '@/assets/icons/account.svg';
import CloseIcon from '@/assets/icons/close.svg';
import FAQIcon from '@/assets/icons/faq.svg';
import HomeIcon from '@/assets/icons/home.svg';
import LogoutIcon from '@/assets/icons/logout.svg';
import SettingIcon from '@/assets/icons/setting.svg';
import authService from '@/services/auth.service';
import { STATUS_CODE } from '@/shared/constants/http-status-code';
import { ROUTE_NAME } from '@/shared/constants/routing';
import { USER_ROLE } from '@/shared/constants/user-role';
import {
  GET_LOGGEDIN_STATUS,
  GET_USER_ID,
  LOG_OUT
} from '@/shared/constants/vuex-key';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'NavBarComponent',
  components: {
    HomeIcon,
    MyAccountIcon,
    FAQIcon,
    LogoutIcon,
    CloseIcon,
    SettingIcon
  },
  data() {
    return {
      drawer: false,
      items: [
        {
          icon: 'HomeIcon',
          text: 'Home',
          route: ROUTE_NAME.INDEX,
          id: 'home',
          allowedRoles: [
            USER_ROLE.PSL_ADMIN,
            USER_ROLE.PSL_AGENT,
            USER_ROLE.PSL_QA,
            USER_ROLE.PSL_CONTACT_CENTRE
          ]
        },
        {
          icon: 'mdi-account-multiple',
          vIcon: true,
          text: 'Possible duplicates',
          route: ROUTE_NAME.POSSIBLE_DUPLICATES,
          id: 'possible-duplicates',
          allowedRoles: [
            USER_ROLE.PSL_ADMIN,
            USER_ROLE.PSL_AGENT,
            USER_ROLE.PSL_QA
          ]
        },
        {
          icon: 'SettingIcon',
          text: 'Admin settings',
          route: ROUTE_NAME.ADMIN_SETTINGS,
          id: 'admin-setting',
          allowedRoles: [USER_ROLE.PSL_ADMIN]
        },
        {
          icon: 'LogoutIcon',
          text: 'Logout',
          route: ROUTE_NAME.LOGIN,
          id: 'logOut',
          allowedRoles: [
            USER_ROLE.PSL_ADMIN,
            USER_ROLE.PSL_AGENT,
            USER_ROLE.PSL_QA,
            USER_ROLE.PSL_CONTACT_CENTRE
          ]
        }
      ],
      hasError: false,
      hasSomeThingWentWrong: false,
      menuAllowedRoles: [
        USER_ROLE.PSL_ADMIN,
        USER_ROLE.PSL_AGENT,
        USER_ROLE.PSL_QA,
        USER_ROLE.PSL_CONTACT_CENTRE
      ]
    };
  },
  computed: {
    ...mapGetters({
      isLoggedIn: GET_LOGGEDIN_STATUS,
      userId: GET_USER_ID
    }),
    showingMenu() {
      return (
        this.isLoggedIn &&
        ![
          ROUTE_NAME.CREATE_LEAD,
          ROUTE_NAME.INTRODUCER,
          ROUTE_NAME.SELECT_REFERRAL_TYPE,
          ROUTE_NAME.LOGIN
        ].includes(this.$route.name)
      );
    }
  },
  methods: {
    ...mapActions([LOG_OUT]),
    async gotoPage(pageDestination) {
      if (
        pageDestination === ROUTE_NAME.LOGIN &&
        this.$route.name !== ROUTE_NAME.EDIT_LEAD
      ) {
        try {
          await this.LOG_OUT({
            userId: this.userId,
            isSessionTimeOut: false
          });
          authService.clearUserInfo();
        } catch (error) {
          if (error && error.statusCode !== STATUS_CODE.INTERNAL_SERVER_ERROR) {
            this.hasError = true;
          } else {
            this.hasSomeThingWentWrong = true;
          }
        }
      }
      this.drawer = !this.drawer;
      if (
        this.$route.name === ROUTE_NAME.LIST_LEADS &&
        pageDestination === ROUTE_NAME.INDEX
      ) {
        window.location.reload();
      } else {
        this.$router.push({ name: pageDestination });
      }
    }
  }
};
