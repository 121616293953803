import {
  CANCEL_PENDING_REQUESTS,
  CLEAR_ABORT_CONTROLLERS
} from '@/shared/constants/vuex-key';
import Vue from 'vue';
import Vuex from 'vuex';
import actions from './actions';
import introducer from './modules/introducer/introducer.module';
import state from './modules/introducer/state';
import lead from './modules/lead/lead.module';
import user from './modules/user/user.module';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    ...state,
    abortController: new AbortController()
  },
  getters: {
    abortController(state) {
      return state.abortController;
    }
  },
  mutations: {
    [CLEAR_ABORT_CONTROLLERS](state) {
      state.abortController = new AbortController();
    }
  },
  actions: {
    ...actions,
    [CANCEL_PENDING_REQUESTS](context) {
      if (context.state.abortController?.signal.aborted === false) {
        context.state.abortController?.abort();
      }
      context.commit(CLEAR_ABORT_CONTROLLERS);
    }
  },
  modules: {
    lead,
    introducer,
    user
  }
});
