export const getInitialState = () => {
  return {
    lead: null,
    leadFormConfig: {},
    listLeads: [],
    leadNotes: { total: 0, value: [] },
    isSearching: false,
    listWorkflowStates: null,
    listWorkflowStatesSelected: [],
    listPslAgent: null,
    listPslAgentSelected: [],
    history: null,
    showHistoryDetail: false,
    historyDetail: null,
    coverTypes: [],
    pslAgents: [],
    callOutComeLists: [],
    nextWorkflowStateList: [],
    listQuote: [],
    noIntroducerCode: false,
    listPossibleDuplicateLeads: []
  };
};
