export const ENDPOINT = {
  INDEX: '/',
  LOGIN: '/login',
  CREATE_LEAD: '/create-lead',
  LIST_LEADS: '/list-leads',
  EDIT_LEAD: '/edit-lead',
  LEAD_DETAIL: '/lead-detail',
  INTRODUCER: '/introducer',
  LIST_INTRODUCER: '/list-introducers',
  HOME: '/home',
  PAGE_NOT_FOUND: '/page-not-found',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  DASHBOARD: '/dashboard',
  ADMIN_SETTINGS: '/admin-settings',
  POSSIBLE_DUPLICATES: '/possible-duplicates',
  SELECT_REFERRAL_TYPE: '/select-referral-type',
  UNMATCHED: '*'
};

export const ROUTE_NAME = {
  INDEX: 'index',
  LOGIN: 'login',
  CREATE_LEAD: 'create-lead',
  LIST_LEADS: 'list-leads',
  EDIT_LEAD: 'edit-lead',
  LEAD_DETAIL: 'lead-detail',
  INTRODUCER: 'introducer-code',
  LIST_INTRODUCER: 'list-introducer',
  PAGE_NOT_FOUND: 'page-not-found',
  FORGOT_PASSWORD: 'forgot-password',
  RESET_PASSWORD: 'reset-password',
  ADMIN_SETTINGS: 'admin-settings',
  POSSIBLE_DUPLICATES: 'possible-duplicates',
  SELECT_REFERRAL_TYPE: 'select-referral-type',
  DASHBOARD: 'dashboard'
};
