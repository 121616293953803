import DAY from '@/shared/constants/day';
import MONTH from '@/shared/constants/month';
import moment from 'moment';
import { DOB_AGE_RANGE } from '../constants/range-date';

export class DateUtil {
  static getCurrentDate() {
    return new Date(this.parseDate(new Date()));
  }

  static getDay(date) {
    return date.getDate() < 10 ? '0' + date.getDate() : '' + date.getDate();
  }

  static getMonth(date) {
    const currentMonthNumber = date.getMonth() + 1;
    return currentMonthNumber < 10
      ? '0' + currentMonthNumber
      : '' + currentMonthNumber;
  }

  static getMonthInText(date) {
    return date.toLocaleString('en-US', {
      month: 'long'
    });
  }

  static getYear(date) {
    return '' + date.getFullYear();
  }

  static addDays(date, days) {
    const copy = new Date(date);

    copy.setDate(copy.getDate() + days);

    return copy;
  }

  static parseDate(date) {
    return `${date.getFullYear()}-${this.getMonth(date)}-${this.getDay(date)}`;
  }

  static getAllowedDates(allowCurrent) {
    const start = new Date();
    if (!allowCurrent) {
      start.setDate(start.getDate() + 1);
    }
    const end = new Date();
    end.setFullYear(start.getFullYear() + 1);
    return { start: moment(start).format(), end: moment(end).format() };
  }

  static getAllowedAge() {
    const start = moment().subtract(DOB_AGE_RANGE.MIN_AGE, 'years').format();
    const end = moment().subtract(DOB_AGE_RANGE.MAX_AGE, 'years').format();
    return { start, end };
  }

  static toFullDate(selectedDate) {
    const day = DAY[selectedDate.getDay()];
    const date = selectedDate.getDate();
    const convertedDate = this.toOriginalDate(date);
    const month = MONTH[selectedDate.getMonth()];
    const year = selectedDate.getFullYear();
    return `${day}, ${convertedDate} ${month} ${year}`;
  }

  static toOriginalDate(date) {
    if (date > 3 && date < 21) return `${date}th`;
    switch (date % 10) {
      case 1:
        return `${date}st`;
      case 2:
        return `${date}nd`;
      case 3:
        return `${date}rd`;
      default:
        return `${date}th`;
    }
  }

  static convertFrom24hTo12h(timeString) {
    let hours = Number(timeString.split(':')[0]);
    const minutes = timeString.split(':')[1];
    const AmOrPm = hours >= 12 ? 'pm' : 'am';

    hours = hours % 12 || 12;

    const finalTime = '' + hours + '.' + minutes + AmOrPm;

    return finalTime;
  }

  static convertFrom12hTo24h(timeString) {
    const period = timeString.slice(-2);
    const isAm = period === 'am';
    let time = timeString.split(period)[0];
    let hours;
    if (isAm) {
      let temp = time.split('.')[0];
      if (temp === '12') {
        hours = '00';
      } else {
        if (temp.length === 1) {
          hours = `0${temp}`;
        } else {
          hours = temp;
        }
      }
    } else {
      let temp = time.split('.')[0];
      if (temp === '12') {
        hours = '12';
      } else {
        hours = `${Number(temp) + 12}`;
      }
    }

    let minutes = Number(time.split('.')[1]);

    if (minutes.toString().length === 1) minutes = `0${minutes}`;

    return `${hours}:${minutes}:00`;
  }

  static isPastDate(date) {
    const currentDay = this.getDay(new Date());
    const currentMonth = this.getMonth(new Date());
    const currentYear = this.getYear(new Date());
    const selectedDay = this.getDay(date);
    const selectedMonth = this.getMonth(date);
    const selectedYear = this.getYear(date);
    if (selectedYear < currentYear) {
      return true;
    } else if (selectedYear === currentYear) {
      if (selectedMonth < currentMonth) {
        return true;
      } else if (selectedMonth === currentMonth) {
        return selectedDay < currentDay;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  static getTimeRangeLabel(timeRange) {
    return `${this.convertFrom24hTo12h(
      timeRange.from
    )} - ${this.convertFrom24hTo12h(timeRange.to)}`;
  }

  static sortTimeRanges(timeRanges) {
    return timeRanges.sort((t1, t2) => {
      const [fromHoursT1, fromMinutesT1] =
        this.convertLabelToTimeRange(t1).from.split(':');
      const [fromHoursT2, fromMinutesT2] =
        this.convertLabelToTimeRange(t2).from.split(':');
      const fromT1 = new Date();

      fromT1.setHours(Number(fromHoursT1));
      fromT1.setMinutes(Number(fromMinutesT1));

      const fromT2 = new Date();

      fromT2.setHours(Number(fromHoursT2));
      fromT2.setMinutes(Number(fromMinutesT2));

      return fromT1.getTime() - fromT2.getTime();
    });
  }

  /**
   * Sort time slots by [From] then by [To]
   * @param {Array<{from: string, to: string}>} timeSlots
   * @returns
   */
  static sortTimeSlot(timeSlots) {
    return timeSlots.sort((s1, s2) => {
      const fromT1 = this.getTimeOnlyDate(s1.from);
      const fromT2 = this.getTimeOnlyDate(s2.from);

      const fromDelta = fromT1.getTime() - fromT2.getTime();
      if (fromDelta !== 0) {
        return fromDelta;
      }
      const toT1 = this.getTimeOnlyDate(s1.to);
      const toT2 = this.getTimeOnlyDate(s2.to);
      return toT1.getTime() - toT2.getTime();
    });
  }
  static sortTimeSlotRevert(timeSlots) {
    return timeSlots.sort((s1, s2) => {
      const fromT1 = this.getTimeOnlyDate(s1.from);
      const fromT2 = this.getTimeOnlyDate(s2.from);

      return fromT2.getTime() - fromT1.getTime();
    });
  }

  static getTimeOnlyDate(timeSpan) {
    const [hour, minute, second] = timeSpan.split(':');

    return new Date(0, 0, 0, hour, minute, second);
  }

  static extractTime(date) {
    const hour = date.getHours();
    const minute = date.getMinutes();
    const time = `${hour < 10 ? '0' + hour : hour}:${
      minute < 10 ? '0' + minute : minute
    }`;
    return time;
  }

  static convertLabelToTimeRange(label) {
    const [from, to] = label.split(' - ');
    const fromIn12h = this.convertFrom12hTo24h(from);
    const toIn12h = this.convertFrom12hTo24h(to);

    return { from: fromIn12h, to: toIn12h };
  }

  static generateGreetings() {
    const now = new Date();
    const hours = now.getHours();
    const minutes = now.getMinutes();

    if (hours >= 0 && hours < 12) {
      return 'Good morning, ';
    }

    if (hours >= 12 && hours < 17) {
      return 'Good afternoon, ';
    }

    if (hours >= 17 && hours <= 23 && minutes <= 59) {
      return 'Good evening, ';
    }

    return '';
  }

  /**
   * Get earliest time-slot and merge it with continuous time-slot
   * @param {Array} contactTimes
   * @returns
   */
  static getEarliestMergedTimeSlot(contactTimes) {
    const contactTimesSorted = JSON.parse(
      JSON.stringify(this.sortTimeSlot(contactTimes))
    );
    for (let index = 0; index < contactTimesSorted.length; index++) {
      if (index >= contactTimesSorted.length - 1) {
        break;
      }
      if (contactTimesSorted[index].to == contactTimesSorted[index + 1].from) {
        contactTimesSorted[index].to = contactTimesSorted[index + 1].to;
        contactTimesSorted.splice(index + 1, 1);
        index--;
      }
    }
    return contactTimesSorted[0];
  }

  /**
   * Find date allocation information based on value provided
   * @param {*} dateToFind
   * @returns {number} returns -1 if not found else return index of date allocation
   */
  static findDateAllocationIndex(dateAllocations, dateToFind) {
    if (dateAllocations == null || dateAllocations.length === 0) {
      return -1;
    }
    // use binary search since date allocation returned from api is sorted in ascending order
    let start = 0,
      end = dateAllocations.length - 1;
    const date = new Date(dateToFind);
    date.setHours(0, 0, 0, 0);
    //[Ly Luu Duc] note that to compare to date object, we can not use == or ===, when you need to compare date equals date, plz use .getTime() == .getTime()
    while (start <= end) {
      const mid = Number.parseInt((start + end) / 2);
      const midDate = new Date(dateAllocations[mid].contactDate);
      midDate.setHours(0, 0, 0, 0);
      if (midDate < date) {
        start = mid + 1;
      } else if (midDate > date) {
        end = mid - 1;
      } else {
        return mid;
      }
    }
    return -1;
  }

  static isWeekends(date) {
    const parsedDate = new Date(date);
    const dayOfTheWeek = parsedDate.getDay();
    return dayOfTheWeek === 0 || dayOfTheWeek === 6;
  }

  static isBankHoliday(bankHolidays, date) {
    if (bankHolidays != null && bankHolidays.length > 0) {
      const index = DateUtil.binarySearchDate(bankHolidays, date);
      return index !== -1;
    }
    return false;
  }

  static binarySearchDate(dateArray, date) {
    if (dateArray == null || dateArray.length === 0) {
      return -1;
    }

    let start = 0,
      end = dateArray.length - 1;
    const dateParsed = new Date(date);
    dateParsed.setHours(0, 0, 0, 0);
    while (start <= end) {
      const mid = Number.parseInt((start + end) / 2);
      const midDate = new Date(dateArray[mid]);
      midDate.setHours(0, 0, 0, 0);
      if (midDate < dateParsed) {
        start = mid + 1;
      } else if (midDate > dateParsed) {
        end = mid - 1;
      } else {
        return mid;
      }
    }
    return -1;
  }
}
