export const API_URL = {
  CHECK_INTRODUCER_CODE: 'introducer/validateCode?code=',
  GET_LIST_INTRODUCER: 'introducer/getListIntroducer',
  GET_LIST_LEADS: 'lead/getListLead',
  GET_POSSIBLE_DUPLICATE_LEADS: 'lead/getListPossibleDuplicate',
  GET_COVER_TYPE: 'coverType',
  GET_HISTORY: 'lead/getHistory',
  GET_LEAD_HISTORY_DETAIL: 'lead/getHistoryDetail',
  GET_QUOTE_DETAIL: 'lead/getQuoteDetail',
  GET_DATE_ALLOCATIONS: 'lead/getListActualAllocation',
  GET_BANK_HOLIDAY: 'lead/getBankHoliday',
  GET_PREFERRED_CONTACT_TIME: 'preferredContactTime',
  GET_LIST_PSL_AGENT: 'user/GetListPSLAgent',
  CREATE_LEAD_ANONYMOUS: 'lead/anonymous/createLead',
  FORGOT_PASSWORD: 'user/forgot_password',
  VALIDATE_CHANGE_KEY: 'user/valid_change_key',
  RESET_PASSWORD: 'user/reset_password',
  LEAD: 'lead',
  ASSIGN_LEAD_TO_USER: 'lead/assignToUser',
  APP_CONFIGURATION: 'appConfiguration',
  LOGIN: 'auth/login',
  LOGIN_QUOTE: 'auth/quote/login',
  LOGOUT: 'auth/logout?userId=',
  NEXT_STATE: '/Lead/NextState',
  GET_WORKFLOW_STATES: 'workflow/getWorkflowStates',
  GET_LEAD_WORKFLOW_STATES: 'lead/getWorkflowStates',
  FETCH_CALL_OUTCOME_LISTS: 'callOutcomeType/getAllCallOutcomeType',
  UPDATE_CALL_OUTCOME: 'lead/nextState',
  UPDATE_ONLINE_CALL_OUTCOME: 'lead/addOnlineCallOutcome',
  ADD_NOTE: 'lead/AddOnlineLeadNote',
  FETCH_NEXT_LEAD_MANUAL_STATE: 'lead/getNextLeadManualStates?leadId=',
  UPDATE_TELEPHONY_LEAD_STATUS: 'lead/updateLeadState',
  CREATE_NEW_QUOTE: 'lead/newQuote',
  FETCH_LIST_QUOTE: 'lead/getLeadQuotes',
  SYNC_QUOTE: 'lead/syncLeadQuote',
  AUTH_IMPERSONATE: 'auth/quote/impersonate',
  REFRESH_QUOTE_TOKEN: 'auth/quote/refreshToken',
  GET_INTRODUCER_DETAIL: 'introducer',
  EXPORT_CONTACT_DUE: 'lead/exportContactDueToday',
  EXPORT_ALL_LEAD: 'lead/exportAllLead',
  FETCH_TIME_SLOTS: 'preferredContactTime',
  CHECK_CAPACITY_VALID: 'preferredContactTime/isNewCapacityValid',
  SAVE_TIME_SLOT_AND_CAPACITY: 'preferredContactTime/updateTimeCapacity',
  SAVE_TIME_SLOT_AND_CAPACITY_REDUCTION: 'capacityReduction',
  FETCH_CAPACITY_REDUCTION: 'capacityReduction',
  GET_LEAD_CALL_NOTES: 'CallOutcomeResult/GetLeadCallNotes',
  GET_ONLINE_LEAD_NOTES: 'Lead/GetOnlineLeadNotes',
  MORTGAGE_CATEGORY: '/MortgageCategory',
  PREFERRED_CONTACT_METHOD: '/PreferredContactMethod',
  CREATE_NEW_TIME_SLOT_AND_CAPACITY: 'preferredContactTime',
  CHECK_HAS_ALLOCATION: 'preferredContactTime/checkHasAllocation',
  SAVE_TIME_RANGE: 'preferredContactTime/updateTimeRange',
  DELETE_TIME_SLOT_AND_CAPACITY: 'preferredContactTime',
  DELETE_CAPACITY_REDUCTION: 'capacityReduction',
  FETCH_RECENT_CONTACT_TIME_CHANGE: 'lead/getRecentContactTimeChangesLogs',
  GET_LIST_D2C_EMAIL_SENT: 'lead/GetD2CEmailsSent',
  RESEND_D2C_EMAIL: 'lead/ResendD2CEmail',
  GET_LIST_D2C_SMS_SENT: 'lead/GetD2CSMSSent',
  RESEND_D2C_SMS: 'lead/ResendD2CSMS'
};
