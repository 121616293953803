import { ROUTE_NAME } from '@/shared/constants/routing';

export const STORAGE_EXPIRED = '12h';
export const IDLE_TIME = 1800000; // 30 mins
export const GREETING_MAX_LENGTH = 30;

export const PS_DEFAULT_OPTIONS = {
  suppressScrollX: true
};

export const PS_TABLE_OPTIONS = {
  suppressScrollY: true
};

export const AMOUNT_INSURED_FORMAT = '0,0';
export const AMOUNT_DEFAULT_FORMAT = '0,0.00';
export const PERCENTAGE_FORMAT = '0.0';

export const SHOW_BANNER_PAGE_LIST = [
  ROUTE_NAME.SELECT_REFERRAL_TYPE,
  ROUTE_NAME.CREATE_LEAD,
  ROUTE_NAME.INTRODUCER
];
