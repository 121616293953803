import { RESET_STORE } from '@/shared/constants/vuex-key';
import { getInitialState as getIntroducerInitialState } from './modules/introducer/initialState';
import { getInitialState as getLeadInitialState } from './modules/lead/initialState';
import { getInitialState as getUserInitialState } from './modules/user/initialState';

export default {
  [RESET_STORE](store) {
    store.state.user = getUserInitialState();
    store.state.lead = getLeadInitialState();
    store.state.introducer = getIntroducerInitialState();
  }
};
