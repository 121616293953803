import { render, staticRenderFns } from "./FooterComponent.vue?vue&type=template&id=b676e4fe&scoped=true"
import script from "./FooterComponent.js?vue&type=script&lang=js&external"
export * from "./FooterComponent.js?vue&type=script&lang=js&external"
import style0 from "./FooterComponent.scss?vue&type=style&index=0&id=b676e4fe&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b676e4fe",
  null
  
)

export default component.exports