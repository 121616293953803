import authService from '@/services/auth.service';
import { PAGE_TITLE } from '@/shared/constants/page-title';
import { ENDPOINT, ROUTE_NAME } from '@/shared/constants/routing';
import { STORAGE_KEY } from '@/shared/constants/storage';
import { USER_ROLE } from '@/shared/constants/user-role';
import { CANCEL_PENDING_REQUESTS } from '@/shared/constants/vuex-key';
import { isAllowed } from '@/shared/utils/isAllowed';
import store from '@/store';
import isEmpty from 'lodash/isEmpty';
import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter);

const routes = [
  {
    path: ENDPOINT.INDEX,
    meta: { title: PAGE_TITLE.LIST_LEADS },
    name: ROUTE_NAME.INDEX,
    redirect: () => ({ name: ROUTE_NAME.LIST_LEADS })
  },
  {
    path: ENDPOINT.INTRODUCER,
    name: ROUTE_NAME.INTRODUCER,
    meta: { title: PAGE_TITLE.INTRODUCER_CODE_PAGE },
    component: () =>
      import(
        /* webpackChunkName: "code-enter" */ '@/views/EnterIntroducerCodeView/EnterIntroducerCodeView.vue'
      )
  },
  {
    path: ENDPOINT.SELECT_REFERRAL_TYPE,
    name: ROUTE_NAME.SELECT_REFERRAL_TYPE,
    meta: { title: PAGE_TITLE.SELECT_REFERRAL_TYPE },
    component: () =>
      import(
        /* webpackChunkName: "code-enter" */ '@/views/ReferralTypeSelectionView/ReferralTypeSelectionView.vue'
      )
  },
  {
    path: ENDPOINT.CREATE_LEAD,
    name: ROUTE_NAME.CREATE_LEAD,
    meta: { title: PAGE_TITLE.LEAD_CAPTURE_FORM_PAGE },
    component: () =>
      import(
        /* webpackChunkName: "lead-capture" */ '@/views/LeadCaptureView/LeadCaptureView.vue'
      )
  },
  {
    path: ENDPOINT.LIST_LEADS,
    name: ROUTE_NAME.LIST_LEADS,
    props: true,
    meta: { isAuthenRequired: true, title: PAGE_TITLE.LIST_LEADS },
    beforeEnter: permissionGuard([
      USER_ROLE.PSL_ADMIN,
      USER_ROLE.PSL_AGENT,
      USER_ROLE.PSL_QA,
      USER_ROLE.PSL_CONTACT_CENTRE
    ]),
    component: () =>
      import(
        /* webpackChunkName: "list-lead" */ '@/views/ListLeadsView/ListLeadsView.vue'
      )
  },
  {
    path: ENDPOINT.EDIT_LEAD,
    name: ROUTE_NAME.EDIT_LEAD,
    props: true,
    meta: { isAuthenRequired: true, title: PAGE_TITLE.EDIT_LEAD },
    beforeEnter: permissionGuard([USER_ROLE.PSL_ADMIN, USER_ROLE.PSL_AGENT]),
    component: () =>
      import(
        /* webpackChunkName: "edit-lead" */ '@/views/LeadCaptureView/LeadCaptureView.vue'
      )
  },
  {
    path: ENDPOINT.LEAD_DETAIL,
    name: ROUTE_NAME.LEAD_DETAIL,
    props: true,
    meta: { isAuthenRequired: true, title: PAGE_TITLE.LEAD_DETAIL },
    beforeEnter: permissionGuard([
      USER_ROLE.PSL_ADMIN,
      USER_ROLE.PSL_AGENT,
      USER_ROLE.PSL_QA,
      USER_ROLE.PSL_CONTACT_CENTRE
    ]),
    component: () =>
      import(
        /* webpackChunkName: "lead-detail" */ '@/views/LeadDetailView/LeadDetailView.vue'
      )
  },
  {
    path: ENDPOINT.POSSIBLE_DUPLICATES,
    name: ROUTE_NAME.POSSIBLE_DUPLICATES,
    meta: { isAuthenRequired: true, title: PAGE_TITLE.POSSIBLE_DUPLICATES },
    beforeEnter: permissionGuard([
      USER_ROLE.PSL_ADMIN,
      USER_ROLE.PSL_AGENT,
      USER_ROLE.PSL_QA
    ]),
    component: () =>
      import(
        /* webpackChunkName: "admin-setting" */ '@/views/PossibleDuplicatesView/PossibleDuplicatesView.vue'
      )
  },
  {
    path: ENDPOINT.ADMIN_SETTINGS,
    name: ROUTE_NAME.ADMIN_SETTINGS,
    meta: { isAuthenRequired: true, title: PAGE_TITLE.ADMIN_SETTING },
    beforeEnter: permissionGuard([USER_ROLE.PSL_ADMIN]),
    component: () =>
      import(
        /* webpackChunkName: "admin-setting" */ '@/views/AdminSettingsView/AdminSettingsView.vue'
      )
  },
  {
    path: ENDPOINT.LOGIN,
    name: ROUTE_NAME.LOGIN,
    meta: { title: PAGE_TITLE.LOGIN_PAGE },
    component: () =>
      import(/* webpackChunkName: "login" */ '@/views/LoginView/LoginView.vue')
  },
  {
    path: ENDPOINT.FORGOT_PASSWORD,
    name: ROUTE_NAME.FORGOT_PASSWORD,
    meta: { isAuthenRequired: false, title: PAGE_TITLE.FORGOT_PASSWORD },
    component: () =>
      import(
        /* webpackChunkName: "forgot-password" */ '@/views/ForgotPasswordView/ForgotPasswordView.vue'
      )
  },
  {
    path: ENDPOINT.RESET_PASSWORD,
    name: ROUTE_NAME.RESET_PASSWORD,
    meta: { isAuthenRequired: false, title: PAGE_TITLE.RESET_PASSWORD },
    component: () =>
      import(
        /* webpackChunkName: "reset-password" */ '@/views/ResetPasswordView/ResetPasswordView.vue'
      )
  },
  {
    path: ENDPOINT.LIST_INTRODUCER,
    name: ROUTE_NAME.LIST_INTRODUCER,
    meta: { isAuthenRequired: true, title: PAGE_TITLE.LIST_INTRODUCER_CODE },
    component: () =>
      import(
        /* webpackChunkName: "distributor" */ '@/views/ListIntroducerView/ListIntroducerView.vue'
      )
  },
  {
    path: ENDPOINT.PAGE_NOT_FOUND,
    name: ROUTE_NAME.PAGE_NOT_FOUND,
    component: () =>
      import(
        /* webpackChunkName: "page-not-found" */ '@/views/PageNotFoundView/PageNotFoundView.vue'
      )
  },
  {
    path: ENDPOINT.UNMATCHED,
    redirect: () => ({ name: ROUTE_NAME.PAGE_NOT_FOUND })
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    document.getElementById('app')?.scrollIntoView();
  }
});
router.beforeEach((toRoute, fromRoute, next) => {
  store.dispatch(CANCEL_PENDING_REQUESTS);
  window.document.title =
    toRoute.meta && toRoute.meta.title ? toRoute.meta.title : 'Paymentshield';
  redirectHandler(toRoute, next);
});

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const redirectHandler = async (toRoute, next) => {
  const token = authService.getToken();
  if (
    toRoute.matched.some((record) => record.meta.isAuthenRequired) &&
    isEmpty(token)
  ) {
    next({ name: ROUTE_NAME.LOGIN });
  } else if (!isEmpty(token)) {
    switch (toRoute.name) {
      case ROUTE_NAME.LOGIN:
        next({ name: ROUTE_NAME.LIST_LEADS });
        break;
      default:
        next();
        break;
    }
  } else next();
};

function permissionGuard(allowedRoles) {
  return (to, from, next) => {
    const currentUserRoles = window.$cookies.get(STORAGE_KEY.ROLES);
    if (!isAllowed(allowedRoles, currentUserRoles)) {
      next({ name: ROUTE_NAME.LIST_LEADS });
    } else {
      next();
    }
  };
}

export default router;
