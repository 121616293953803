export const LEAD_UPDATE_FIELD = {
  FIRST_NAME: 'FirstName',
  LAST_NAME: 'LastName',
  PHONE_NUMBER: 'PhoneNumber',
  PREFERRED_CONTACT_DATE: 'PreferredContactDate',
  PREFERRED_CONTACT_TIME: 'LeadPreferredContactTimes',
  STATUS: 'CurrentWorkflowState',
  LEAD_COVER_TYPES: 'LeadCoverTypes',
  ADDITIONAL_DETAIL: 'AdditionalDetail',
  EMAIL: 'Email',
  ASSIGN_TO_USER_ID: 'AssignToUserId',
  LAST_CONTACT: 'LastContact',
  CALL_OUTCOME_TYPE_ID_STANDARD_INBOUND: 'CallOutcomeTypeId_Standard_Inbound',
  CALL_OUTCOME_TYPE_ID_NON_STANDARD_INBOUND:
    'CallOutcomeTypeId_Non-standard_Inbound',
  CALL_OUTCOME_TYPE_ID: 'CallOutcomeTypeId',
  CALL_OUTCOME_TYPE_ID_STANDARD_OUTBOUND: 'CallOutcomeTypeId_Standard_Outbound',
  CALL_OUTCOME_TYPE_ID_NON_STANDARD_OUTBOUND:
    'CallOutcomeTypeId_Non-standard_Outbound',
  MORTGAGE_CATEGORY_ID: 'MortgageCategoryId',
  EXCHANGE_DATE: 'ExchangeDate',
  PREFERRED_CONTACT_METHOD_CODE: 'PreferredContactMethodCode',
  CALL_OUTCOME_NOTES: 'Notes',
  //online
  RECEIVED_SUPPORT: 'ReceivedSupport'
};
