import { AMOUNT_DEFAULT_FORMAT } from '@/shared/constants/common.constants';
import {
  DATE_FORMAT_DDMMMMYYYY,
  DATE_FORMAT_DDMMYYYY,
  DATE_FORMAT_DDMMYYYYHHMMSS
} from '@/shared/constants/date.constants';
import { HISTORY_EVENT } from '@/shared/constants/history-event';
import REFERRAL_TYPE from '@/shared/constants/lead-type';
import { LEAD_UPDATE_FIELD } from '@/shared/constants/lead-update-field';
import moment from 'moment';
import numeral from 'numeral';
import Vue from 'vue';
const timeFormat12h = Vue.filter('timeFormat12h', (time) => {
  return moment(time, 'hh:mm:ss').format('h.mma');
});
const timeFormatHHMM = Vue.filter('timeFormatHHMM', (time) => {
  return moment(time, 'hh:mm:ss').format('HH:mm');
});

const dateTimeFormat = Vue.filter('dateTimeFormat', (dateTime, format) => {
  return moment(dateTime).format(format);
});

const dateTimeLocalFormat = Vue.filter(
  'dateTimeLocalFormat',
  (dateTime, format) => {
    return moment.utc(dateTime).local().format(format);
  }
);

const date = Vue.filter('date', (date) => {
  return date ? moment(date).format(DATE_FORMAT_DDMMMMYYYY) : 'N/A';
});

const dateDDMMYYYYYHHMMSS = Vue.filter('dateDDMMYYYYYHHMMSS', (date) => {
  return moment(date).format(DATE_FORMAT_DDMMYYYYHHMMSS);
});

const dateSpecial = Vue.filter('dateSpecial', (date) => {
  const today = new Date();
  today.setDate(today.getDate());

  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  const t = new Date(date);
  if (today.toDateString() === t.toDateString()) {
    return 'TODAY';
  }

  if (tomorrow.toDateString() === t.toDateString()) {
    return 'TOMORROW';
  }

  return moment(date).format(DATE_FORMAT_DDMMYYYY);
});

const dateDDMMYYYY = Vue.filter('dateDDMMYYYY', (date) => {
  return moment(date).format(DATE_FORMAT_DDMMYYYY);
});

const referralTypeText = Vue.filter('referralTypeText', (text) => {
  switch (text) {
    case REFERRAL_TYPE.TELEPHONY:
      return 'Phone';
    default:
      return text;
  }
});

const truncateString = Vue.filter('truncateString', (string, maximumLength) => {
  const stringTruncate =
    string.length <= maximumLength
      ? string
      : string.substr(0, maximumLength) + '...';

  return stringTruncate;
});

const summaryTitle = Vue.filter('summaryTitle', (detail) => {
  switch (detail) {
    case HISTORY_EVENT.CREATE_LEAD:
      return 'Lead created.';
    case HISTORY_EVENT.UPDATE_LEAD:
      return 'Information updated.';
    case HISTORY_EVENT.UPDATE_LEAD_CONTACT_DATETIME:
      return 'Contact preferences updated.';
    case HISTORY_EVENT.CREATE_CALL_REQUEST_FOR_LEAD:
      return 'Call request created.';
    case HISTORY_EVENT.CALL_REQUEST_OUTCOME_UPDATED:
      return 'Call request outcome updated.';
    case HISTORY_EVENT.QUOTE_CREATED:
      return 'Quote created.';
    case HISTORY_EVENT.UPDATE_CALL_REQUEST_FOR_LEAD:
      return 'Call request outcome updated.';
    case HISTORY_EVENT.EDIT_TIME_SLOT:
      return 'Time slot updated.';
    case HISTORY_EVENT.NOTE_ADDED:
      return 'Note added.';
    default:
      return detail;
  }
});

const leadUpdateField = Vue.filter('leadUpdateField', (detail) => {
  switch (detail) {
    case LEAD_UPDATE_FIELD.FIRST_NAME:
      return "'First name' updated";
    case LEAD_UPDATE_FIELD.LAST_NAME:
      return "'Last name' updated";
    case LEAD_UPDATE_FIELD.EMAIL:
      return "'Email' updated";
    case LEAD_UPDATE_FIELD.LEAD_COVER_TYPES:
      return "'Cover required' updated";
    case LEAD_UPDATE_FIELD.PREFERRED_CONTACT_DATE:
      return "'Contact due date' updated";
    case LEAD_UPDATE_FIELD.PREFERRED_CONTACT_TIME:
      return "'Contact due time' updated";
    case LEAD_UPDATE_FIELD.STATUS:
      return "'Status' updated";
    case LEAD_UPDATE_FIELD.ADDITIONAL_DETAIL:
      return "'Additional detail' updated";
    case LEAD_UPDATE_FIELD.PHONE_NUMBER:
      return "'Phone number' updated";
    case LEAD_UPDATE_FIELD.ASSIGN_TO_USER_ID:
      return "'Assign to' updated";
    case LEAD_UPDATE_FIELD.LAST_CONTACT:
      return "'Last contact' updated";
    case LEAD_UPDATE_FIELD.CALL_OUTCOME_TYPE_ID:
      return "'Call outcome' updated";
    case LEAD_UPDATE_FIELD.CALL_OUTCOME_TYPE_ID_STANDARD_INBOUND:
      return "'Call outcome' updated (Inbound call)";
    case LEAD_UPDATE_FIELD.CALL_OUTCOME_TYPE_ID_NON_STANDARD_INBOUND:
      return "'Call outcome' updated (Non-standard - Inbound call)";
    case LEAD_UPDATE_FIELD.CALL_OUTCOME_TYPE_ID_STANDARD_OUTBOUND:
      return "'Call outcome' updated (Outbound call)";
    case LEAD_UPDATE_FIELD.CALL_OUTCOME_TYPE_ID_NON_STANDARD_OUTBOUND:
      return "'Call outcome' updated (Non-standard - Outbound call)";
    case LEAD_UPDATE_FIELD.MORTGAGE_CATEGORY_ID:
      return "'Mortgage category' updated";
    case LEAD_UPDATE_FIELD.EXCHANGE_DATE:
      return "'Exchange date' updated";
    case LEAD_UPDATE_FIELD.PREFERRED_CONTACT_METHOD_CODE:
      return "'Preferred contact method' updated";
    case LEAD_UPDATE_FIELD.RECEIVED_SUPPORT:
      return "'Received support' updated";
    case LEAD_UPDATE_FIELD.CALL_OUTCOME_NOTES:
      return "'Notes' updated";
    default:
      return detail;
  }
});

export const formatCurrency = Vue.filter('formatCurrency', (val, format) => {
  return '£' + numeral(val).format(format || AMOUNT_DEFAULT_FORMAT);
});
export const formatTimeSlots = Vue.filter('formatTimeSlots', (val) => {
  const label = val.from.slice(0, -3) + ' - ' + val.to.slice(0, -3);
  return label;
});

export const coverTypeName = Vue.filter(
  'coverTypeName',
  function (coverTypeId, coverList) {
    const coverType = coverList.find((cover) => cover.id === coverTypeId);
    return coverType ? coverType.name : undefined;
  }
);

export default {
  timeFormat12h,
  timeFormatHHMM,
  dateTimeFormat,
  dateTimeLocalFormat,
  dateDDMMYYYYYHHMMSS,
  date,
  truncateString,
  dateSpecial,
  dateDDMMYYYY,
  summaryTitle,
  referralTypeText,
  leadUpdateField,
  formatCurrency,
  formatTimeSlots,
  coverTypeName
};
