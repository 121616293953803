import { API_URL } from '@/shared/constants/api-url';
import axiosClient from '@/shared/http/axios-client';

class ReferenceDataService {
  async getCoverList() {
    const response = await axiosClient.get(API_URL.GET_COVER_TYPE);
    return response.data;
  }
  async getPreferredContactTimes() {
    const response = await axiosClient.get(API_URL.GET_PREFERRED_CONTACT_TIME);
    return response.data;
  }
  async getAppConfiguration() {
    const response = await axiosClient.get(API_URL.APP_CONFIGURATION);
    return response.data;
  }
  async getAppConfigurationByCode(code) {
    const response = await axiosClient.get(
      API_URL.APP_CONFIGURATION + `/${code}`
    );
    return response.data;
  }
  async getMortgageCategory() {
    const response = await axiosClient.get(API_URL.MORTGAGE_CATEGORY);
    return response.data;
  }
  async getPreferredContactMethod() {
    const response = await axiosClient.get(API_URL.PREFERRED_CONTACT_METHOD);
    return response.data;
  }
}

export default new ReferenceDataService();
