import filters from '@/filters';
import { IDLE_TIME } from '@/shared/constants/common.constants';
import IdleVue from 'idle-vue';
import VueMask from 'v-mask';
import Vue from 'vue';
import VueCookies from 'vue-cookies';
import App from './App.vue';
import permission from './directives/permission';
import './global.scss';
import vuetify from './plugins/vuetify';
import './registerServiceWorker';
import router from './router';
import store from './store';

Vue.config.productionTip = false;
Vue.use(VueCookies);
const eventsHub = new Vue();
Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  idleTime: IDLE_TIME
});
Vue.use(VueMask);
Vue.directive('permission', permission);
new Vue({
  router,
  store,
  vuetify,
  filters,
  render: (h) => h(App)
}).$mount('#app');
