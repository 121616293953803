import { API_URL } from '@/shared/constants/api-url';
import {
  OPEN_STATUS_TEXTS,
  TELEPHONY_LEAD_STATUS_TEXT
} from '@/shared/constants/lead-status';
import LEAD_TYPE from '@/shared/constants/lead-type';
import { QuoteStatus, QuoteStatusText } from '@/shared/constants/quote-status';
import getQuoteStatusText from '@/shared/constants/quote-status.js';
import axiosClient from '@/shared/http/axios-client';
import { DateUtil } from '@/shared/utils/date-util';
import { Helper } from '@/shared/utils/helper';
import pagingUtils from '@/shared/utils/paging-utils';
import {
  getOrderForQueryParams,
  getSearchForQueryParams
} from '@/shared/utils/service-utils';
class LeadService {
  convertApiModelToClientModel(model) {
    return {
      ...model,
      actionBtn: model.code,
      isContactDueInPast: DateUtil.isPastDate(new Date(model.contactDue)),
      contactTimesMerged: DateUtil.getEarliestMergedTimeSlot(model.contactTimes)
    };
  }

  async getLeadDetail(id, mergeTime = false) {
    const response = await axiosClient.get(
      `${API_URL.LEAD}/${id}?mergeTime=${mergeTime}`
    );
    return response.data.value;
  }

  async editLead(lead) {
    const response = await axiosClient.put(`${API_URL.LEAD}`, lead);
    return response.data;
  }

  createLead(leadInfo) {
    return axiosClient.post(`${API_URL.CREATE_LEAD_ANONYMOUS}`, leadInfo);
  }

  async assignToUser(leadId, userId) {
    const response = await axiosClient.put(`${API_URL.ASSIGN_LEAD_TO_USER}`, {
      leadId,
      userId
    });
    return response.data;
  }

  /**
   *
   * @param {number} page
   * @param {{sortBy?: string, sortDesc?: boolean}} sorting
   * @param {string} keyword
   */
  async fetchListPossibleDuplicateLeads(page, sorting, keyword) {
    const options = {
      params: {
        page,
        $search: getSearchForQueryParams(keyword),
        $orderby: getOrderForQueryParams(sorting)
      }
    };

    const { data } = await axiosClient.get(
      API_URL.GET_POSSIBLE_DUPLICATE_LEADS,
      options
    );

    return {
      total: data.total,
      paging: {
        current: page,
        pageCount: data.totalPage,
        pageSize: data.value.length
      },
      data: data.value,
      sorting
    };
  }

  async fetchListLeads(paging, sorting, keyword, filter, leadType) {
    const { pageSize, current } = paging;
    const { workflowStates, assignTo } = filter;
    const options = {
      params: {
        $count: true,
        $top: pageSize,
        $skip: current > 1 ? pageSize * (current - 1) : 0,
        $search: `"${keyword.trim().replace(/"/g, '\\"')}"`,
        referralType: leadType
      }
    };

    if (sorting.sortBy !== '' && sorting.sortBy != null) {
      options.params.$orderby = `${sorting.sortBy} ${
        sorting.sortDesc ? 'desc' : 'asc'
      }`;
    }

    let filterQuery = '';
    if (assignTo?.length > 0) {
      filterQuery = `assigntouserid in (${assignTo})`;
    }

    if (workflowStates?.length > 0) {
      if (filterQuery.length) {
        filterQuery += ' and ';
      }
      filterQuery += `status in (${workflowStates})`;
    }

    if (filterQuery) {
      options.params.$filter = filterQuery;
    }

    const response = await axiosClient.get(API_URL.GET_LIST_LEADS, options);

    return {
      total: response.data.total,
      paging: {
        current,
        pageCount: Math.ceil(response.data.total / pageSize),
        pageSize
      },
      sorting,
      data: response.data.value.map((introducer) =>
        this.convertApiModelToClientModel(introducer)
      )
    };
  }
  /**
   * Get List of psl agent with pageSize, orderBy and lstUserId(for ordering by specific users, <b>will be overridden by orderBy</b>)
   * @param {{pageSize: number, lstUserId: number[], orderBy: string}} params
   * @returns
   */
  async getListPSLAgent(params) {
    params ??= {};
    let { pageSize, lstUserId, orderBy } = params;
    const options = {
      params: {
        $count: true,
        $top: pageSize,
        $skip: 0,
        $orderby: orderBy,
        userIds: null
      }
    };
    const userIdSet = new Set(lstUserId);
    if (userIdSet.size > 0) {
      options.params.userIds = Array.from(userIdSet).join(',');
    }
    const response = await axiosClient.get(API_URL.GET_LIST_PSL_AGENT, options);
    const data = [];
    // push users in the set to the top
    if (userIdSet.size > 0 && response?.data?.value?.length > 0) {
      userIdSet.forEach((userId) => {
        const users = response.data.value.find((x) => x.id == userId);
        // should be impossible since the data will contains user provided
        if (users == null) {
          return;
        }
        data.push(users);
      });
      if (data.length !== response.data.value.length) {
        const rest = response.data.value.filter(
          (x) => userIdSet.has(x.id) === false
        );
        data.push(...rest);
      }
    } else {
      data.push(...response.data.value);
    }

    return {
      total: response.data.total,
      data: data
    };
  }
  async getWorkflowStates(workflowStateQueryOption) {
    const { pageSize, leadType } = workflowStateQueryOption;
    const options = {
      params: {
        $count: true,
        $top: pageSize,
        $skip: 0,
        $orderby: 'name asc'
      }
    };
    const response = await axiosClient.get(
      `${API_URL.GET_LEAD_WORKFLOW_STATES}/${leadType}`,
      options
    );

    return {
      total: response.data.total,
      data: response.data.value
    };
  }

  async getLeadHistory(leadId, paging) {
    const { pageSize, current } = paging;
    const options = {
      params: {
        $skip: pageSize * (current - 1),
        $top: pageSize
      }
    };
    const response = await axiosClient.get(
      `${API_URL.GET_HISTORY}/${leadId}`,
      options
    );
    return {
      total: response.data.total,
      paging: {
        current,
        pageCount: Math.ceil(response.data.total / pageSize),
        pageSize
      },
      data: response.data.value.map((h) => {
        if (h.created) {
          const parsedDateTime = new Date(h.created);
          const date = `${DateUtil.getDay(
            parsedDateTime
          )} ${DateUtil.getMonthInText(
            parsedDateTime
          )} ${parsedDateTime.getFullYear()}`;
          const time = DateUtil.extractTime(parsedDateTime);
          h.created = `${date}, ${time}`;
        }
        let name = '';
        if (h.firstName && h.firstName.length > 0) {
          name += h.firstName;
        }
        if (h.surname && h.surname.length > 0) {
          if (name != '' && h.userId !== 0) {
            name += ' ';
            name += h.surname[0];
          } else {
            name += h.surname;
          }
        }
        h.userName = name;
        return h;
      })
    };
  }
  async getLeadHistoryDetail(historyId) {
    const response = await axiosClient.get(
      `${API_URL.GET_LEAD_HISTORY_DETAIL}/${historyId}`
    );
    return response.data;
  }

  async fetchCallOutcomeLists(referralType) {
    const response = await axiosClient.get(
      `${API_URL.FETCH_CALL_OUTCOME_LISTS}/${referralType}`
    );
    return response.data;
  }

  async getListPSLAgents() {
    const apiOptions = {
      $count: true,
      $skip: 0
    };
    const response = await axiosClient.get(
      API_URL.GET_LIST_PSL_AGENT,
      apiOptions
    );
    return response.data;
  }

  async triggerNextState(nextStageModel) {
    const response = await axiosClient.post(API_URL.NEXT_STATE, nextStageModel);
    return response.data;
  }

  async updateCallOutcome(object) {
    const response = await axiosClient.post(
      `${API_URL.UPDATE_CALL_OUTCOME}/${object}`
    );
    return response.data;
  }

  async addOnlineCallOutcome(onlineCallOutcomeModel) {
    const response = await axiosClient.post(
      API_URL.UPDATE_ONLINE_CALL_OUTCOME,
      onlineCallOutcomeModel
    );
    return response.data;
  }

  async addNote(object) {
    const response = await axiosClient.post(API_URL.ADD_NOTE, object);
    return response.data;
  }

  async getNextLeadManualStates(leadId) {
    const response = await axiosClient.get(
      API_URL.FETCH_NEXT_LEAD_MANUAL_STATE + leadId
    );
    return response.data;
  }

  async updateLeadStatus(nextStageModel) {
    const response = await axiosClient.post(
      API_URL.UPDATE_TELEPHONY_LEAD_STATUS,
      nextStageModel
    );
    return response.data;
  }

  async createANewQuote(nextStageModel) {
    const response = await axiosClient.post(
      API_URL.CREATE_NEW_QUOTE,
      nextStageModel
    );
    return response.data;
  }

  // eslint-disable-next-line no-unused-vars
  async getListQuoteHistory(leadId, paging) {
    const { pageSize, current } = paging;
    const options = {
      params: {
        id: leadId,
        $skip: pageSize * (current - 1),
        $top: pageSize,
        $count: true
      }
    };
    const response = await axiosClient.get(API_URL.FETCH_LIST_QUOTE, options);
    return Helper.mapResponseToTableData(response, paging);
  }

  async syncQuote(leadId) {
    const url = `${API_URL.SYNC_QUOTE}?id=${leadId}`;
    const response = await axiosClient.post(url);
    return response.data;
  }

  async getQuoteDetail(quoteRequestId) {
    const url = `${API_URL.GET_QUOTE_DETAIL}/${quoteRequestId}`;
    const response = await axiosClient.get(url);
    return response.data;
  }

  replaceToken(tokenObj, quoteItem) {
    if (tokenObj?.data?.value?.token) {
      const token = tokenObj.data.value.token;
      quoteItem.quoteStatusText = getQuoteStatusText(quoteItem);
      const isExpired = quoteItem?.quoteStatusText === QuoteStatusText.Expired;
      let href;
      if (
        quoteItem.journey != null &&
        quoteItem.journey.toLowerCase() === 'v2'
      ) {
        href = `${quoteItem.quoteDomain}/?userId=${quoteItem.brokerId}&token=${token}&quoteRequestId=${quoteItem.quoteRequestId}&isAnnualRequote=${quoteItem.isAnnualRequote}&isExpired=${isExpired}`;
      } else {
        href = `${quoteItem.quoteDomain}/QuoteRequests/LoginWithAccessToken?userId=${quoteItem.brokerId}&token=${token}&redirectUri=`;
        // view readonly if quote is submitted
        let uriByQuoteStatus;
        if (quoteItem.quoteStatus === QuoteStatus.Submitted) {
          uriByQuoteStatus = `ViewReadOnly`;
        } else if (isExpired) {
          uriByQuoteStatus = `ReopenQuote`;
        } else {
          // continue otherwise
          uriByQuoteStatus = `Continue`;
        }
        href += encodeURIComponent(
          `/QuoteRequests/${uriByQuoteStatus}/${quoteItem.quoteRequestId}`
        );
      }
      return href;
    }
  }

  async getDateAllocations(fromDate, toDate) {
    const url = `${API_URL.GET_DATE_ALLOCATIONS}`;
    const options = {
      params: {}
    };
    if (fromDate != null) {
      options.params.fromDate = fromDate;
    }
    if (toDate != null) {
      options.params.toDate = toDate;
    }
    const response = await axiosClient.get(url, options);
    return response.data;
  }

  shouldCheckDateAllocation(leadDetail) {
    return (
      leadDetail == null ||
      (leadDetail.status !== TELEPHONY_LEAD_STATUS_TEXT.POSSIBLE_DUPLICATE &&
        OPEN_STATUS_TEXTS.includes(leadDetail.status))
    );
  }

  async getBankHoliday() {
    const url = `${API_URL.GET_BANK_HOLIDAY}`;
    const response = await axiosClient.get(url);
    return response.data;
  }

  async exportContactDue() {
    try {
      const url = `${API_URL.EXPORT_CONTACT_DUE}`;
      const response = await axiosClient.get(url, {
        responseType: 'blob'
      });
      const objectUrl = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = objectUrl;
      link.setAttribute('download', `Telephony_Leads_contact_due_today.csv`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Export all lead by referral type
   * @param {string} referralType
   */
  async exportAllLead(referralType) {
    try {
      const url = `${API_URL.EXPORT_ALL_LEAD}`;
      const response = await axiosClient.get(url, {
        params: { referralType: referralType },
        responseType: 'blob'
      });
      const objectUrl = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = objectUrl;
      const fileName =
        referralType.toLowerCase() === LEAD_TYPE.ONLINE.toLowerCase()
          ? 'All_Online_Leads.csv'
          : 'All_Telephony_Leads.csv';
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error(error);
    }
  }

  async getListTimeSlot() {
    const url = `${API_URL.FETCH_TIME_SLOTS}`;
    const response = await axiosClient.get(url);
    return response.data;
  }

  async getListCapacityReduction() {
    const url = `${API_URL.FETCH_CAPACITY_REDUCTION}`;
    const response = await axiosClient.get(url);
    return response.data;
  }

  async checkIsCapacityValid(payload) {
    const url = `${API_URL.CHECK_CAPACITY_VALID}`;
    const response = await axiosClient.post(url, payload);
    return response.data;
  }

  async saveTimeSlotAndCapacity(payload) {
    const url = `${API_URL.SAVE_TIME_SLOT_AND_CAPACITY}`;
    const response = await axiosClient.put(url, payload);
    return response.data;
  }

  async saveTimeSlotAndReductionCapacity(payload) {
    const url = `${API_URL.SAVE_TIME_SLOT_AND_CAPACITY_REDUCTION}`;
    const response = await axiosClient.post(url, payload);
    return response.data;
  }

  async createTimeSlotAndCapacity(payload) {
    const url = `${API_URL.CREATE_NEW_TIME_SLOT_AND_CAPACITY}`;
    const response = await axiosClient.post(url, payload);
    return response.data;
  }

  async saveTimeRange(payload) {
    const url = `${API_URL.SAVE_TIME_RANGE}`;
    const response = await axiosClient.put(url, payload);
    return response.data;
  }

  async deleteTimeSlotAndCapacity(id) {
    const url = `${API_URL.DELETE_TIME_SLOT_AND_CAPACITY}/${id}`;
    const response = await axiosClient.delete(url);
    return response.data;
  }

  async deleteCapacityReduction(id) {
    const url = `${API_URL.DELETE_CAPACITY_REDUCTION}/${id}`;
    const response = await axiosClient.delete(url);
    return response.data;
  }
  async fetchLeadNotes({ id, paging, leadType }) {
    const options = pagingUtils.convertPagingToAPIOptions(paging);
    const response = await axiosClient.get(
      `${
        leadType == LEAD_TYPE.ONLINE
          ? API_URL.GET_ONLINE_LEAD_NOTES
          : API_URL.GET_LEAD_CALL_NOTES
      }/${id}`,
      options
    );
    return response.data;
  }

  async checkHasAllocation(id) {
    const response = await axiosClient.get(
      `${API_URL.CHECK_HAS_ALLOCATION}/${id}`
    );
    return response.data;
  }

  async getRecentContactTimeChangesLogs(id) {
    const response = await axiosClient.get(
      `${API_URL.FETCH_RECENT_CONTACT_TIME_CHANGE}/${id}`
    );
    return response.data;
  }
  async getListD2CEmailSent(leadId) {
    const response = await axiosClient.get(
      `${API_URL.GET_LIST_D2C_EMAIL_SENT}/${leadId}`
    );
    return response.data;
  }
  async resendD2CEmail(payload) {
    const response = await axiosClient.post(API_URL.RESEND_D2C_EMAIL, payload);
    return response.data;
  }
  async getListD2CSMSSent(leadId) {
    const response = await axiosClient.get(
      `${API_URL.GET_LIST_D2C_SMS_SENT}/${leadId}`
    );
    return response.data;
  }
  async resendD2CSMS(payload) {
    const response = await axiosClient.post(API_URL.RESEND_D2C_SMS, payload);
    return response.data;
  }
}

export default new LeadService();
