import router from '@/router/index';
import authService from '@/services/auth.service';
import { STATUS_CODE } from '@/shared/constants/http-status-code';
import { ROUTE_NAME } from '@/shared/constants/routing';
import { SET_SESSION_TIME_OUT_STATUS } from '@/shared/constants/vuex-key';
import store from '@/store/index';
import axios from 'axios';
const axiosClient = axios.create({
  baseURL: process.env.VUE_APP_ROOT_API,
  headers: {
    'Content-type': 'application/json'
  }
});

axiosClient.interceptors.request.use((request) => {
  return requestHandler(request);
});

/**
 * Config axios request
 * @param {import('axios').InternalAxiosRequestConfig} request
 * @returns
 */
const requestHandler = (request) => {
  // Get abort controller and assign it's signal for cancellation
  request.signal = store.getters.abortController?.signal;

  const token = authService.getToken();
  const tokenQuote = authService.getTokenQuote();
  if (token) {
    request.headers.Authorization = `Bearer ${token}`;
  }
  if (tokenQuote) {
    request.headers.quote_session_token = tokenQuote;
  }

  return request;
};

axiosClient.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error)
);

/**
 *
 * @param {import('axios').AxiosResponse} response
 * @returns
 */
const successHandler = (response) => {
  if (
    response &&
    response.data &&
    response.data.statusCode &&
    response.data.statusCode !== STATUS_CODE.SUCCESS
  ) {
    return Promise.reject(response.data);
  }
  return response;
};

const errorHandler = (error) => {
  if (error?.response?.status === STATUS_CODE.UNAUTHORIZED) {
    authService.clearUserInfo();
    store.commit(SET_SESSION_TIME_OUT_STATUS, true);
    router.push({ name: ROUTE_NAME.LOGIN });
  }
  const errorRes = error?.response?.data ?? error;
  return Promise.reject(errorRes);
};

export default axiosClient;
