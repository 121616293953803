import { GET_CURRENT_USER_ROLES } from '@/shared/constants/vuex-key';
import { isAllowed } from '@/shared/utils/isAllowed';

export default {
  inserted(el, binding, vnode) {
    // v-permission value
    const allowedRoles = binding.value;

    // Current user roles, stored from VueX store
    const currentUserRoles =
      vnode.context.$store.getters[GET_CURRENT_USER_ROLES];

    if (!isAllowed(allowedRoles, currentUserRoles)) {
      vnode.elm.parentElement.removeChild(vnode.elm);
    }
  }
};
