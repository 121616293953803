import {
  GET_INTRODUCER_DETAIL,
  GET_LIST_INTRODUCER
} from '@/shared/constants/vuex-key';

export default {
  [GET_LIST_INTRODUCER](state) {
    return state.introducerList;
  },
  [GET_INTRODUCER_DETAIL](state) {
    return state.introducerDetail;
  }
};
